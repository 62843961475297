export const UPDATE = 'Modifier'
export const DELETE = 'Supprimer'
export const CONNEXION = 'Connexion'
export const VALIDATE = `Valider`
export const OK = 'Ok'
export const SUBSCRIPTION = 'Inscription'
export const SELECT = `Sélectionner`
export const SIGN_CONTRACT = `Signez votre contrat`
export const PAYMENT = 'Paiement'
export const CARD_FORGOTTEN = `J'ai oublié ma carte`
export const RETURN_HOME = `Retour à l'accueil`
export const REBOOT_PASSWORD = `Réinitialiser le mot de passe`
export const UNLOCK_MY_ACCOUNT = `Débloquer mon compte`
export const SIGN_AUTHORIZATION = `Signez votre autorisation`
export const AUTHORIZATION_SIGNED = `Autorisation signée ? Cliquez ici`
export const CONTRACT_SIGNED = `Contrat signé ? Cliquez ici`