import { useEffect, useState } from "react"

import countryCodes from 'country-codes-list'

const UseCountryCode = () => {
  const countryCodeList = countryCodes.customList('countryNameEn', '{countryNameEn} +{countryCallingCode}')
  const [formatedList, setFormatedList] = useState([])

  const formateCountryList = () => {
    const code_list = []
    let i = 0;

    for (let code in countryCodeList) {
      code_list.push(<option key={i} value={'+' + countryCodeList[code].split('+')[1]}>{countryCodeList[code]}</option>)
      i++
    }
    setFormatedList(code_list)
  }

  useEffect(() => {
    formateCountryList()
  }, [])

  return formatedList
}

export default UseCountryCode
