import DatePicker from 'react-datepicker'
import { registerLocale } from  "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import fr from 'date-fns/locale/fr';

import { UPDATE_APPOINTMENT_TODAY } from "../utils/text/modal_titles"
import { CREATE_ACCOUNT } from '../utils/text/various_messages';

registerLocale('fr', fr)

const ConfigureDatePicker = ({startDate, setStartDate, modalTitle}) => {
  let max_date = Date.parse(dayjs().add(1, 'months'))
  const handleCalendarOpen = () => {
    document.addEventListener('touchstart', (event) => {
        event.stopPropagation();
    }, true);
  };

  switch (modalTitle) {
    case UPDATE_APPOINTMENT_TODAY:
      max_date = startDate;
      break;
    case CREATE_ACCOUNT:
      max_date = null;
      break;
    default:
      break
  }

  return (
    <DatePicker
      className="input"
      withPortal
      minDate={new Date()}
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      maxDate={max_date}
      // modalTitle === UPDATE_APPOINTMENT_TODAY ? startDate : 
      locale="fr"
      dateFormat="dd/MM/yyyy"
      onCalendarOpen={handleCalendarOpen}
    />
  )
}

export default ConfigureDatePicker