import { url_api } from "./config";

const fetchFormulas = async (token, gym_id, ) => {
  try {
    const formulas = await fetch(`${url_api}/formulas/gyms/${gym_id}?add_trial_session=true`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    }) 

    return formulas.json()
  } catch (err) {
    throw err
  }
}
export default fetchFormulas