import { SELECT } from '../utils/text/button_titles'

const GymCell = (gym, index, handleClick) => {
  return (
      <div className="box my-3 py-3" key={index}>
        <img src={gym.picture} alt="Devanture LSDT" className="card" />
         <div className="card-header-title">
           <strong>{gym.name}</strong>
         </div>
         <div className="card-header-title">
           <strong>{gym.adress}, {gym.zip_code} {gym.city}</strong>
         </div>
         <div className="has-text-centered">
           <button onClick={() => handleClick(gym.id)} className="button is-success is-fullwidth">{SELECT}</button>
         </div>
      </div>
  )
}

export default GymCell