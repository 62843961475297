import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import { Redirect } from 'react-router-dom'

import { selectUserComplementaryData, setUserContractData, setUserContractDataSigned } from '../features/user/userSlice'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import LoadSpinner from '../items/Load_spinner'

import UseErrorModal from '../hooks/Use_error_modal'
import UseUserIds from '../hooks/Use_user_ids'
import UseRedirection from '../hooks/Use_redirection'
import UseInfoModal from '../hooks/Use_info_modal'
import UseSelectedFormula from '../hooks/Use_selected_formula'

import { CONTRACT_SIGNED, SIGN_CONTRACT } from '../utils/text/button_titles'
import postContractData from '../utils/post_contract_data'
import { yousign_app_uri } from '../utils/config'
import checkStatusContract from '../utils/check_contract_status'
import { SIGNATURE_STATUS_IN_PROGRESS, SIGNATURE_STATUS_REFUSED } from '../utils/text/various_messages'
import { LONG_SUBSCRIPTION, ONE_MONTH_SUBSCRIPTION } from '../utils/text/payment_parameters'

function ContactPage() {
  const dispatch = useDispatch()
  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const [InfoModal, setToggleInfoModal, setInfoMessage] = UseInfoModal()
  const redirection = UseRedirection()
  const [access_token, , user_id] = UseUserIds()
  const selected_formula = UseSelectedFormula()

  const complementary_data = useSelector(selectUserComplementaryData)

  const [yousignUrl, setYousignUrl] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const [redirectComplementaryData, setRedirectComplementaryData] = useState(null)
  const [redirectPayment, setRedirectPayment] = useState(false)


  useEffect(() => {
    (async () => {
      try {
        const yousign_data = !complementary_data ? {
          contract_id: localStorage.getItem('contract_id'),
          member_id: localStorage.getItem('member_id')
        } : await postContractData(complementary_data, selected_formula, user_id, access_token)

        if (!yousign_data.contract_id) {
          return setRedirectComplementaryData(true)
        }
        dispatch(setUserContractData(yousign_data))
        localStorage.setItem('contract_id', yousign_data.contract_id)
        localStorage.setItem('member_id', yousign_data.member_id)
        const yousign_url = `${yousign_app_uri}/procedure/sign?members=${yousign_data.member_id}`

        setIsReady(true)
        setYousignUrl(yousign_url)
      }
      catch (err) {
        console.log(err)
        setToggleErrorModal(true)
        if (err.message) {
          setErrorMessage(err.message)
        }
      }
    })()
  }, [])

  const checkSignature = async () => {
    try {
      const contract_id = localStorage.getItem('contract_id')
      const contract_status = await checkStatusContract(access_token, user_id, contract_id)

      if (contract_status.status === 'signed') {
        dispatch(setUserContractDataSigned(contract_status))
        setRedirectPayment(true)
      }
      else if (contract_status.status === 'inProgress') {
        setInfoMessage(SIGNATURE_STATUS_IN_PROGRESS)
        setToggleInfoModal(true)
      }
      else {
        setInfoMessage(SIGNATURE_STATUS_REFUSED)
        setToggleInfoModal(true)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      {redirectComplementaryData && <Redirect push to="/complete_subscription" />}
      {redirectPayment && <Redirect push to={`/payment_component?payment_parameter=${selected_formula.payment_type === 'invoice' ? ONE_MONTH_SUBSCRIPTION : LONG_SUBSCRIPTION}`} />}
      <Navbar />
      <Hero title={`Consulter et signer votre contrat.`} />
      {!isReady && <LoadSpinner />}
      {isReady && <div className='columns'>
        <div className='column is-6 is-offset-3'>
          <div className='has-text-centered mx-6 my-6'>
            <a className='button is-info' href={yousignUrl} target="_blank" rel="noreferrer">{SIGN_CONTRACT}</a>
          </div>
        </div>
      </div>}
      {isReady && <div className='columns'>
        <div className='column is-6 is-offset-3'>
          <div className='has-text-centered mx-6 my-6'>
            <div className='button is-success' onClick={async () => await checkSignature()}>{CONTRACT_SIGNED}</div>
          </div>
        </div>
      </div>}
      <ErrorModal />
      <InfoModal />
      <Footer />
    </section>
  )
}

export default ContactPage