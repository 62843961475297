import { url_api } from "./config";

const fetchUserData = async (token, user_id) => {
  const fetch_user_data = await fetch(`${url_api}/users/${user_id}/app`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    mode: 'cors'
  });
  const user_data = await fetch_user_data.json()

  return [user_data.user_data, user_data.user_sessions, user_data.user_subscription ]
}

export default fetchUserData;