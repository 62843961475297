const NODE_ENV = process.env.NODE_ENV;

let url_api = '';
let yousign_app_uri = '';
let privacy_policy_link = `https://dltb3sry3wjhx.cloudfront.net/`

if (NODE_ENV === 'development') {
  // url_api = 'http://localhost:8080';
  // url_api = 'https://lsdt-api-integration.lasalledutemps.com'
  url_api = 'https://lsdt-api-production.lasalledutemps.com'
  // yousign_app_uri = `https://staging-app.yousign.com`;
  yousign_app_uri = 'https://webapp.yousign.com';
}
else if (NODE_ENV === 'integration') {
  url_api = 'https://lsdt-api-production.lasalledutemps.com'
  // url_api = 'https://lsdt-api-integration.lasalledutemps.com'
  // url_api = 'https://lsdt-chat-server-integ.lasalledutemps.com'
  // yousign_app_uri = `https://staging-app.yousign.com`;
  yousign_app_uri = 'https://webapp.yousign.com';
}
else if (NODE_ENV === 'production') {
  url_api = 'https://lsdt-api-production.lasalledutemps.com'
  // url_api = 'https://lsdt-chat-server-prod.lasalledutemps.com'
  // url_api = 'https://lsdt-chat-server-integ.lasalledutemps.com'
  yousign_app_uri = 'https://webapp.yousign.com';
}

export { url_api, yousign_app_uri, privacy_policy_link };