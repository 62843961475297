import dayjs from "dayjs";

import { url_api } from "./config";

const fetchTrialAccessToken = async () => {
  try {
    const fetch_trial_access_token = await fetch(
      `${url_api}/login/app/trial`,
      {
        method: 'GET',
        headers: {
          message: `LSDT_${dayjs().format('DD/MM/YYYY')}_HelloWorld_abcd`,
        },
        mode: 'cors',
      },
    );
    const trial_access_token = await fetch_trial_access_token.json();
    
    return trial_access_token;
  }
  catch (err) {
    throw err
  }
}

export default fetchTrialAccessToken;