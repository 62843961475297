
import { url_api } from "./config"

const recordUniqueSession = async (access_token, user_id, gym_id, unique_session_data) => {
  try {
    const record_unique_session = await fetch(`${url_api}/subscriptions/app/unique_session/payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        start_date: unique_session_data.date,
        end_date: unique_session_data.date,
        training_session_hour: unique_session_data.hour,
        user_id: user_id,
        gym_id: gym_id,
        activity: unique_session_data.activity,
        formula_type: 'unique_session'
      }),
      mode: 'cors'
    })
    const record_confirmartion = await record_unique_session.text();
  }
  catch (err) {
    throw err
  }
}

export default recordUniqueSession