
import { useState, useEffect } from "react"

import UseUserIds from "../hooks/Use_user_ids"
import fetchMessage from '../utils/fetch_message'

const Message = () => {
  const [gymMessage, setGymMessage] = useState(null)
  const [ , gym_id, ] = UseUserIds()

  useEffect(() => {
    (async () => {
      try {
        if (gym_id) {
          const gym_message = await fetchMessage(gym_id)
  
          if (gym_message.is_active) {
            setGymMessage(gym_message)
          }
          else {
            setGymMessage(null)
          }
        }
      }
      catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <>
    {
      gymMessage
      ?
      <div className="columns">
        <div className="column is-6 is-offset-3">
          <div className="mt-6">
            <span className="has-text-weight-bold">Actualité:</span>
            <div className="box has-text-centered">
              {gymMessage.message}
            </div>
          </div>
        </div>
      </div>
      :
      <div />
    }
    </>
  )
}

export default Message;