import { url_api } from "./config";

const unlockAccount = async (access_token, user_id, gym_id) => {
  try {
    const unlock_account = await fetch(
      `${url_api}/payments/unlock_account`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          user_id: user_id,
          gym_id: gym_id,
        }),
        mode: 'cors',
      },
    );
    const payment_confirmation = await unlock_account.text();

    if (JSON.parse(payment_confirmation) && JSON.parse(payment_confirmation).message === 'Le paiement a correctement été enregistré') {  
      return 'Vous pouvez dès maintenant reprendre des rendez-vous ! Vous allez recevoir votre facture par email.'
    }
  }
  catch (err) {
    throw err
  }
}

export default unlockAccount