import DatePicker from 'react-datepicker'
import { registerLocale } from  "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr)

const ConfigureDatePickerBirthDate = ({setBirthDate, birth_date}) => {
  const max_date = Date.parse(dayjs().subtract(15, 'years'))
  const handleCalendarOpen = () => {
    document.addEventListener('touchstart', (event) => {
        event.stopPropagation();
    }, true);
  };

  return (
    <DatePicker
      className="input"
      withPortal
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={birth_date ? birth_date : max_date}
      onChange={(date) => setBirthDate(date)}
      maxDate={max_date}
      locale="fr"
      dateFormat="dd/MM/yyyy"
      onCalendarOpen={handleCalendarOpen}
    />
  )
}

export default ConfigureDatePickerBirthDate