import { url_api } from "./config";

const recordTrialSession = async (access_token, user_id, gym_id, trial_session_data) => {
  try {
    const record_trial_session = await fetch(
      `${url_api}/subscriptions/app/trial/payment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          start_date: trial_session_data.date,
          end_date: trial_session_data.date,
          training_session_hour: trial_session_data.hour,
          user_id: user_id,
          gym_id: gym_id,
          activity: trial_session_data.activity
            ? trial_session_data.activity
            : 'SUPER-TRAINING',
          formula_type: 'trial',
        }),
        mode: 'cors',
      },
    );
    const record_confirmation = await record_trial_session.text();
  }
  catch (err) {
    throw err
  }
}

export default recordTrialSession