import { url_api } from "./config";


const postContractData = async (complementary_data, selected_formula, user_id, acccess_token) => {
  try {
    let endpoint = `yousign_v2/contracts/app/users`

    if (!complementary_data.wire_date) {
      endpoint = `yousign_v2/contracts/limited_formula/app/users`
    }

    console.log('complementary_data => ', complementary_data)

    const post_contract_data = await fetch(`${url_api}/${endpoint}/${
      user_id
    }`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${acccess_token}`,
      },
      body: JSON.stringify({
        start_date: complementary_data.starting_date,
        encrypted_iban: complementary_data.iban,
        encrypted_bic: complementary_data.bic,
        debit_date: complementary_data.wire_date,
        subscription_formula: selected_formula.formula,
        curent_subscription_type: selected_formula.type,
        image_right: complementary_data.image_right,
        code_promo: complementary_data.promotion_code,
        is_iban_provided:
          complementary_data.iban === '' ? false : true,
        is_bic_provided:
          complementary_data.bic === '' ? false : true,
        coach: complementary_data.coach_name,
      },),
      mode: 'cors'
    })
    const yousign_data = await post_contract_data.json();

    return yousign_data
  }
  catch (err) {
    throw err
  }
}

export default postContractData