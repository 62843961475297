import { Link } from "react-router-dom";
import { useState } from 'react'
import { useDispatch } from 'react-redux';

import { resetToken } from '../features/token/tokenSlice'
import { resetGym } from "../features/gym/gymSlice";
import { resetUser } from "../features/user/userSlice";
import { resetAppointments } from "../features/appointments/appointmentSlice";
import { BOOKING, APPOINTMENTS, PROFILE } from "../utils/text/link_title"

const Navbar = () => {  
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(resetToken())
    dispatch(resetGym())
    dispatch(resetUser())
    dispatch(resetAppointments())
    localStorage.clear()
    window.location.reload()
  }
  const toggleMenu = () => setIsActive(!isActive)

  return (
  <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src="https://imageslsdt.s3.eu-west-3.amazonaws.com/LSDT_logo_definitif.png" alt="logo LSDT composé des initiales" width="112" height="28" />
        </Link>

        <div role="button" className={isActive ? "navbar-burger is-active" : "navbar-burger"} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={toggleMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navbarBasicExample" className={isActive ? "navbar-menu is-active" : "navbar-menu"}>
        <div className="navbar-start">
          <Link className="navbar-item" to="/booking">
            {BOOKING}
          </Link>
          <Link className="navbar-item" to="/appointments">
            {APPOINTMENTS}
          </Link>
          <Link className="navbar-item" to="/profile">
            {PROFILE}
          </Link>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons" onClick={handleClick}>
              <Link className="button is-danger" to="/">
                <strong>Deconnexion</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar