import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import { selectFormula, setFormula } from "../features/formulas/formulaSlice";
import fetchSelectedFormula from "../utils/fetch_selected_formula";

const UseSelectedFormula = () => {
  const dispatch = useDispatch()
  const [selectedFormula, setSelectedFormula] = useState(useSelector(selectFormula))

  if (selectedFormula) {
    return selectedFormula
  }
  let formula_id = localStorage.getItem('selected_formula_id')

  if (!formula_id) {
    formula_id = localStorage.getItem('formula_id')
  }
  const access_token = localStorage.getItem('access_token')

  if (formula_id && access_token) {
    (async () => {
      setSelectedFormula(await fetchSelectedFormula(access_token, formula_id))

      dispatch(setFormula(selectedFormula))
    })()
  }
  return selectedFormula
}

export default UseSelectedFormula