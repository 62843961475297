import { useEffect, useState } from "react"

import { Redirect } from "react-router-dom"

import { INFO } from "../utils/text/modal_titles"
import { OK } from "../utils/text/button_titles"

const UseInfoModal = () => {  
  const [toggleInfoModal, setToggleInfoModal] = useState(false)
  const [infoMessage, setInfoMessage] = useState(null)
  const [redirection, setRedirectionModal] = useState(false)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (toggleInfoModal) {
      setToggleInfoModal(true)
      setInfoMessage(infoMessage)
    }
  }, [])

  const InfoModal = () => {
    return (
      <>
        {redirect && <Redirect push to="/" />}
        <div className={toggleInfoModal ? 'modal is-active' : 'modal'}>
          <div className="modal-background" />
          <div className="modal-card px-6">
            <header className="modal-card-head has-background-info has-text-white">
              <p className="modal-card-title has-text-white">{INFO}</p>
              <button className="delete" aria-label="close" onClick={() => {
                  setToggleInfoModal(false)
                  if (redirection) {
                    setRedirect(true)
                  }
                }} />
            </header>
            <section className="modal-card-body">
              <h4 className="sutitle">{infoMessage}</h4>
              <br />
              <div className="has-text-centered">
                <button className="button is-fullwidth" onClick={() => {
                  setToggleInfoModal(false)
                  if (redirection) {
                    setRedirect(true)
                  }
                  }}>{OK}</button>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }

  return [InfoModal, setToggleInfoModal, setInfoMessage, setRedirectionModal]
}

export default UseInfoModal