import { url_api } from "./config"

const fetchGymData = async (token, gym_id, user_id) => {
  const fetch_gym_data = await fetch(`${url_api}/gyms/${gym_id}/users/${user_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  })
  const gym_data = await fetch_gym_data.json()

  return gym_data
}

export default fetchGymData;