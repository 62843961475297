import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  access_token: null,
  trial_access_token: null
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.access_token = action.payload;
    },
    resetToken: (state) => {
      state.access_token = null;
    },
    setTrialToken: (state, action) => {
      state.trial_access_token = action.payload;
    },
    resetTrialToken: (state) => {
      state.trial_access_token = null;
    },
  }
});

export const selectToken = (state) => state.token.access_token

export const selectTrialToken = (state) => state.token.trial_access_token

export const { setToken, resetToken, setTrialToken, resetTrialToken } = tokenSlice.actions;

export default tokenSlice.reducer;
