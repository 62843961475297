const selectAppointmentCellClassName = (presence) => {
  let presence_class_name = ''
  
  switch (presence) {
    case true:
      presence_class_name = "message is-success my-5 mx-5 px-0 py-0";
      break;
    case false:
      presence_class_name = "message is-danger my-5 mx-5 px-0 py-0";
      break;
    default:
      presence_class_name = "message is-dark my-5 mx-5 px-0 py-0";
  }

  return presence_class_name
}

export default selectAppointmentCellClassName