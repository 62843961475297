const Hero = (props) => {
  return (
    <section className="hero is-black has-shadow">
      <div className="hero-body">
        <div className="container">
          <h1 className="subtitle">
            {props.title}
          </h1>
        </div>
      </div>
    </section>
  )
}

export default Hero;