import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selected_formula: null
};

export const formulaSlice = createSlice({
  name: 'formula',
  initialState,
  reducers: {
    setFormula: (state, action) => {
      state.selected_formula = action.payload;
    },
  }
});

export const selectFormula = (state) => state.formula.selected_formula;

export const { setFormula } = formulaSlice.actions;

export default formulaSlice.reducer;
