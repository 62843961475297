export const WRONG_PASSWORD_OR_EMAIL = `L'email ou le mot de passe renseigné est incorrect. Merci de renouveler votre saisie.`
export const AN_ERROR_HAPPENED = `Une erreur est survenue merci de renouveler votre saisie.`
export const CONNEXION_BREAKED = `La connexion est intérrompue, merci de renouveller votre saisie ultérieurement`
export const FAILED_TO_FETCH = `Failed to fetch`
export const ERROR = `Erreur`
export const SLOT_IS_FULL = `Le créneau horaire sélectionné est complet, merci de selectionner un nouveau créneau`
export const SLOT_HAS_BEEN_TAKEN = `Oups, le créneau que vous aviez selectionné vient d'être pris, merci de sélectionner un nouvel horaire depuis la page d'accueil`
export const PASSWORD_CONFIRMATION_ERROR = `Le mot de passe et sa confirmation ne sont pas identiques. Merci de renouveler votre saisie.`
export const ERROR_DEFAULT_MESSAGE = `Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.`
export const IBAN_ERROR = `L'IBAN renseigné n'est pas valide. Merci d'en renseigner un nouveau.`
export const WRONG_EMAIL = `L'email renseigné ne correspond à aucun compte client LA SALLE DU TEMPS.`
export const BIC_ABSENCE = `Merci de renseigner votre BIC pour poursuivre votre inscription.`
export const USER_ALREADY_EXISTS = `Un utilisateur avec cette adresse email est déjà enregistré à La Salle Du Temps`