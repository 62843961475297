import { url_api } from "./config";
import encryptPassword from "./encrypt_password";

import { USER_ALREADY_EXISTS } from "./text/error_messages";

const SubscribeGuestUser = async (user_data, gym_id, trial_token) => {
  try {
    console.log('user_data => ', user_data)
    const submit_subcription = await fetch(
      `${url_api}/subscriptions/app/guest`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${trial_token}`,
        },
        body: JSON.stringify({
          first_name: user_data.first_name,
          last_name: user_data.last_name,
          adress: user_data.adress,
          zip_code: user_data.zip_code,
          city: user_data.city,
          country_code: user_data.country_code,
          phone_number: user_data.phone_number,
          birth_date: user_data.birthdate,
          email: user_data.email,
          password: await encryptPassword(user_data.password),
          subscription_type: 'guest',
          fk_gym_id: gym_id,
          gender: user_data.gender,
        }),
        mode: 'cors',
      },
    );
    const subscription_confirmation = await submit_subcription.text();

    if (subscription_confirmation === USER_ALREADY_EXISTS) {
      throw subscription_confirmation
    }
    const parsed_string = JSON.parse(subscription_confirmation)

    if (typeof parsed_string === 'object') {
      return parsed_string  
    }
    return subscription_confirmation
  }
  catch (err) {
    console.log(err)
    throw err
  }
}

export default SubscribeGuestUser