import { useState } from 'react'

import { Redirect } from 'react-router-dom'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import UseRedirection from '../hooks/Use_redirection'
import UseStartingData from '../hooks/Use_starting_data'
import UseMultipleSelector from '../hooks/Use_multiple_selector'
import AppointmentCell from '../items/Appointment_cell'
import Tabs from '../items/Tabs'
import { NEXT_APPOINTMENTS, PAST_APPOINTMENTS } from '../utils/text/link_title'
import { UPDATE_OR_DELETE_APPOINTMENT } from '../utils/text/modal_titles'
import deleteAppointment from '../utils/delete_appointment'
import { AN_ERROR_HAPPENED, ERROR } from '../utils/text/error_messages'
import { OK, UPDATE, DELETE } from '../utils/text/button_titles'
import BookingForm from '../items/Booking_form'

function Appointments() {
  const redirection = UseRedirection()
  const [selectedTab, setTab] = useState(NEXT_APPOINTMENTS)
  const [toggleModal, setToggleModal] = useState(false)
  const [deletable, setDeletable] = useState(false)
  const [modalTitle, setModalTitle] = useState(UPDATE_OR_DELETE_APPOINTMENT)
  const [appointmentId, setAppointmentId] = useState(null)
  const [initialAppointment, setInitialAppointment] = useState(null)
  const [toggleErrorModal, setToggleErrorModal] = useState(false)
  const [toggleFormModal, setToggleFormModal] = useState(false)
  // const [trialSession, setTrialSession] = useState(false)
  
  UseStartingData()
  const [
    user_subscription, 
    user_data, 
     ,
    gym_data, 
    gym_activities, 
    access_token,
    next_appointments, 
    past_appointments
  ] = UseMultipleSelector()
  const subscription_type = user_subscription ? user_subscription.Formula.type : null
  const user_id = user_data ? user_data.id : null
  const next_appointments_list = next_appointments ? next_appointments.map((appointment, index) => {
    return AppointmentCell(appointment, index, NEXT_APPOINTMENTS, setToggleModal, setDeletable, setModalTitle, setAppointmentId, setInitialAppointment) 
  }) : []
  const past_appointments_list = past_appointments ? past_appointments.map((appointment, index) => {
    return AppointmentCell(appointment, index, PAST_APPOINTMENTS) 
  }) : []

  const selectTab = (selected_tab) => {
    return setTab(selected_tab)
  }

  return (
    <>
      {redirection && <Redirect push to="/" />}
      <Navbar />
      <Hero title="Gérer vos séances" />
      <Tabs selectTab={selectTab} selectedTab={selectedTab} />
      {
        selectedTab === NEXT_APPOINTMENTS
        ?
        next_appointments_list
        :
        past_appointments_list
      }
      <Footer />

      <div className={toggleModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card px-6">
          <header className="modal-card-head">
            <div className="container">
              <p className="modal-card-title">{modalTitle}</p>
            </div>
            <button className="delete" aria-label="close" onClick={() => setToggleModal(false)} />
          </header>
          <footer className="modal-card-foot">
            <button className="button is-success is-fullwidth" onClick={async () => {
              setToggleModal(false)
              setToggleFormModal(true)
            }}>{UPDATE}</button>
            {
              deletable ?
              (<button className="button is-danger is-fullwidth" onClick={async () => {
                try {
                  setToggleModal(false)
                  await deleteAppointment(access_token, user_id, appointmentId, subscription_type)
                  return window.location.reload()
                }
                catch (err) {
                  setToggleErrorModal(true)
                }
              }}>{DELETE}</button>)
              :
              <div />
            }
          </footer>
        </div>
      </div>

      <div className={toggleErrorModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card px-6">
          <header className="modal-card-head has-background-danger has-text-white">
            <p className="modal-card-title has-text-white">{ERROR}</p>
            <button className="delete" aria-label="close" onClick={() => setToggleErrorModal(false)} />
          </header>
          <section className="modal-card-body">
            <h4 className="sutitle">{AN_ERROR_HAPPENED}</h4>
            <br />
            <div className="has-text-centered">
              <button className="button is-fullwidth" onClick={() => setToggleErrorModal(false)}>{OK}</button>
            </div>
          </section>
        </div>
      </div>
      
      <div className={toggleFormModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card px-6">
          <header className="modal-card-head">
            <button className="delete" aria-label="close" onClick={() => setToggleFormModal(false)} />
          </header>
          <section className="modal-card-body">
            <BookingForm 
              gym_activities={gym_activities} 
              user_data={user_data} 
              gym_data={gym_data} 
              access_token={access_token} 
              appointment_id={appointmentId}
              modalTitle={modalTitle}
              initialAppointment={initialAppointment}
            />
          </section>
        </div>
      </div>

    </>
  )
}

export default Appointments