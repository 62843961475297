import { useEffect, useState } from "react"

import { useSelector } from "react-redux"

import { Link } from "react-router-dom"

import UseUserIds from "../hooks/Use_user_ids"
import UseErrorModal from "../hooks/Use_error_modal"
import UseInfoModal from "../hooks/Use_info_modal"
import UseStartingData from "../hooks/Use_starting_data"
import UseMultipleSelector from "../hooks/Use_multiple_selector"
import UseRedirection from '../hooks/Use_redirection'

import { selectGymData } from "../features/gym/gymSlice"

import Footer from "../items/Footer"
import Hero from "../items/Hero"
import Navbar from "../items/Navbar"

import { privacy_policy_link } from "../utils/config"
import fetchReferralCode from "../utils/fetch_referral_code"
import { MY_INFORMATIONS, PRIVACY_POLICY } from "../utils/text/link_title"
import { COPY_LEGEND, REFERRAL_CODE, PROFILE_TITLE, CONTACT } from "../utils/text/various_messages"
import createContactJsx from "../utils/create_contact_jsx"
import { Redirect } from "react-router-dom"

const Profile = () => {
  const [referralCode, setReferralCode] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isInvoiceSubscription, setIsInvoiceSubscription] = useState(false)
  const [sessionTaken, setSessionTaken] = useState(false)

  UseStartingData()
  const [access_token, , user_id] = UseUserIds()
  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const [InfoModal, setToggleInfoModal, setInfoMessage] = UseInfoModal()
  const redirection = UseRedirection()

  const [user_subscription, ] = UseMultipleSelector()
  const gym_data = useSelector(selectGymData)

  useEffect(() => {
    (async () => {
      try {
        const referral_code = await fetchReferralCode(access_token, user_id)

        setReferralCode(referral_code)
      }
      catch (err) {
        setToggleErrorModal(true)
        if (err.message) {
          setErrorMessage(err.message)
        }
      }
    })()
  }, [])

  useEffect (() => {
    if (user_subscription && user_subscription.end_date) {
      setEndDate(user_subscription.end_date)
    }
    if (user_subscription && user_subscription.InvoicesSubscriptions.length !== 0) {
      setSessionTaken(user_subscription.InvoicesSubscriptions[0].sessions_taken)
      setIsInvoiceSubscription(true)
    }
  }, [user_subscription])

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      <Navbar />
      <Hero title={PROFILE_TITLE} />
      <div className="columns my-6 py-6 mx-3 px-3">
        <div className="column is-6 is-offset-3">
          {endDate && <div className="box has-text-centered">
            <div className='subtitle'>{`Votre abonnement se termine le ${endDate}`}</div>
            {sessionTaken && <div className="subtitle">{`Vous avez pris ${sessionTaken} séances.`}</div>}
          </div>}
          <div className="box has-text-centered">
            <Link className="subtitle" to="/my_informations">
              {MY_INFORMATIONS}
            </Link>
          </div>
          <div className="box has-text-centered">
            <div className='subtitle'>{REFERRAL_CODE} :</div>
            <div className='subtitle has-text-weight-bold is-6 button is-info' onClick={() => {navigator.clipboard.writeText(referralCode)}}>{referralCode}</div>
            <p className="help is-info">{COPY_LEGEND}</p>
          </div>
          <div className="box has-text-centered">
            <div className='subtitle' onClick={() => {
              setToggleInfoModal(true)
              setInfoMessage(createContactJsx(gym_data.name, gym_data.adress, gym_data.zip_code, gym_data.city, gym_data.administrator_email, 0+gym_data.phone_number))
            }}>{CONTACT}</div>
          </div>
          <div className="box has-text-centered">
            <a className='subtitle' href={privacy_policy_link} target="_blank" rel="noreferrer">{PRIVACY_POLICY}</a>
          </div>
        </div>
      </div>
      <Footer />
      <InfoModal />
      <ErrorModal />
    </section>
  )
}

export default Profile