import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Redirect, Link } from 'react-router-dom';

import { setUserId, setUserData, setUserSubscription } from '../features/user/userSlice'
import { setGymId } from '../features/gym/gymSlice';
import { setToken } from '../features/token/tokenSlice'
import encryptPassword from '../utils/encrypt_password'
import {url_api} from '../utils/config'
import { CONNEXION, SUBSCRIPTION } from '../utils/text/button_titles'
import { WRONG_PASSWORD_OR_EMAIL, AN_ERROR_HAPPENED, CONNEXION_BREAKED, FAILED_TO_FETCH } from '../utils/text/error_messages'
import { INFORMATION_RETRIEVAL, PASSWORD, EMAIL } from '../utils/text/various_messages';
import '../App.css'

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [redirection, setRedirection] = useState(false);

  const dispatch = useDispatch()

  const handleClick = async (e) => {
    try {
      e.preventDefault();
      setPending(true);
      const login = await fetch(`${url_api}/login/app`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'cache-control': 'no-cache',
        },
        body: JSON.stringify({
          login: email,
          password: await encryptPassword(password)
        }),
        mode: 'cors'
      });

      setPending(false);
      setError(null);
      if (login.status === 401) {
        return setError(WRONG_PASSWORD_OR_EMAIL)
      }
      if (!login.ok) {
        return setError(AN_ERROR_HAPPENED)
      }
      const login_response = await login.json()

      localStorage.setItem('user_id', login_response.user_id)
      localStorage.setItem('access_token', login_response.access_token)
      localStorage.setItem('gym_id', login_response.gym_id)
      localStorage.setItem('formula_id', login_response.user_subscription.Formula.id)
      dispatch(setToken(login_response.access_token))
      dispatch(setUserId(login_response.user_id))
      dispatch(setUserData(login_response.user_data))
      dispatch(setUserSubscription(login_response.user_subscription))
      dispatch(setGymId(login_response.gym_id))
      setRedirection(true);
      return;
    }
    catch (err) {
      setPending(false);
      if (err.message === FAILED_TO_FETCH) {
        setError(CONNEXION_BREAKED);
      }
    }
  };

  return (
    <section className="App-header">
    {
      redirection
      ?
      <Redirect push to="/booking" />
      :
        (
        <>
          <form onSubmit={(e) => handleClick(e)} className="px-6 mx-6">
            <div className="fields">
              <label className="label has-text-black">{EMAIL}</label>
              <div className="control has-icons-left">
                <input className="input" type="text" placeholder={EMAIL} name="email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase().trim())} required/>
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </div>
            </div>
            <br />
            <div className="fields">
              <label className="label has-text-black">{PASSWORD}</label>
              <div className="control has-icons-left">
                <input className="input" type="password" placeholder={PASSWORD} name="password" value={password} onChange={(e) => setPassword(e.target.value.trim())} required/>
                <span className="icon is-small is-left">
                  <i className="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <br />
            <div className="control">
              <button type="submit" className="button is-success is-fullwidth" value="Valider">{CONNEXION}</button>
            </div>
            <div className="control mt-4">
              <Link to="/create_account">
                <button className="button is-info is-fullwidth" value="Valider">
                  {SUBSCRIPTION}
                </button>
              </Link>
            </div>
            <div className="control mt-4 has-text-centered has-text-white">
              <Link to="/forgotten_password">
              <button className="button is-light is-small is-fullwidth" value="Valider">
                 Mot de passe oublié
              </button>
              </Link>
            </div>
            <br />
          </form>
        </>
      )
     }
      {error && 
        (<div className="box mx-6">
          <div className="is-size-6 has-text-danger has-text-centered">{error}</div>
        </div>)}
      {pending && 
        (<div className="box mx-6">
          <div className="is-size-6 has-text-info has-text-centered">{INFORMATION_RETRIEVAL}</div>
        </div>)}
    </section>
  );
}

export default Login;

