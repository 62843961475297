import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Redirect } from 'react-router-dom'
import dayjs from 'dayjs'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import ConfigureDatePicker from '../items/Configure_date_picker'

import UseStartingData from '../hooks/Use_starting_data'
import UseUserIds from '../hooks/Use_user_ids'
import UseErrorModal from '../hooks/Use_error_modal'
import UseRedirection from '../hooks/Use_redirection'
import UseInfoModal from '../hooks/Use_info_modal'

import { setUserComplementaryData } from '../features/user/userSlice'
import { selectFormula, setFormula } from '../features/formulas/formulaSlice'
import { selectGymData } from '../features/gym/gymSlice'

import checkIban from '../utils/check_iban'
import { VALIDATE } from '../utils/text/button_titles'
import { BIC, COACH_NAME, CREATE_ACCOUNT, IBAN, IMAGE_RIGHT, PROMOTION_CODE, STARTING_DATE, WIRE_DATE, KNOW_MORE } from '../utils/text/various_messages'
import fetchPublicKey from '../utils/fetch_public_key'
import encryptWithRsa from '../utils/encrypt_with_rsa'
import { IBAN_ERROR, BIC_ABSENCE } from '../utils/text/error_messages'
import fetchSelectedFormula from '../utils/fetch_selected_formula'
import generateImageRightText from '../utils/generate_image_right_text'
import setFormOrRedirection from '../utils/set_form_or_redirection'

function CompleteSubscription() {
  UseStartingData()
  
  const [startingDate, setStartingDate] = useState(new Date())
  const [iban, setIban] = useState('')
  const [wireDate, setWireDate] = useState('')
  const [promotionCode, setPromotionCode] = useState('')
  const [coachName, setCoachName] = useState('')
  const [publicKey, setPublicKey] = useState(null)
  const [redirectContractPage, setRedirectContractPage] = useState(false)
  const [isImageRightChecked, setIsImageRightChecked] = useState(false)
  const [simpleForm, setSimpleForm] = useState(false)
  const [redirectPayment, setRedirectPayment] = useState(false)
  const [bic, setBic] = useState('')
  const [isBic, setIsBic] = useState(null)

  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const [InfoModal, setToggleInfoModal, setInfoMessage] = UseInfoModal()

  const selected_formula = useSelector(selectFormula)
  const gym_data = useSelector(selectGymData)

  // const selected_formula = UseSelectedFormula()
  const [acccess_token, , user_id] = UseUserIds()
  const redirection = UseRedirection()

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      try {
        const public_key = await fetchPublicKey(acccess_token, user_id)

        setPublicKey(public_key)
      }
      catch (err) {
        if (err.message) {
          setErrorMessage(err.message)
        }
        setToggleErrorModal(true)
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      try {
        if (!selected_formula) {
          const formula_id = localStorage.getItem('selected_formula_id')
          const fetch_selected_formula = await fetchSelectedFormula(acccess_token, formula_id)

          dispatch(setFormula(fetch_selected_formula))
          setFormOrRedirection(fetch_selected_formula, setSimpleForm, setRedirectPayment)
        }
        else {
          setFormOrRedirection(selected_formula, setSimpleForm, setRedirectPayment)
        }
      }
      catch (err) {
        if (err.message) {
          setErrorMessage(err.message)
        }
        setToggleErrorModal(true)
      }
    })()
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      let next_step = iban ? false : true

      if (iban) {
        if (isBic && !bic) {
          throw new Error(BIC_ABSENCE)
        }
        const encrypted_iban = encryptWithRsa(iban, publicKey)
        const encrypted_bic = isBic ? encryptWithRsa(bic, publicKey) : null
        const check_iban = await checkIban(acccess_token, user_id, encrypted_iban, encrypted_bic)

        if (check_iban === IBAN_ERROR) {
          throw check_iban
        }
        else {
          next_step = true
        }
      }
      if (next_step) {
        dispatch(setUserComplementaryData({
          starting_date: dayjs(startingDate).format('DD-MM-YYYY'),
          iban: iban ? encryptWithRsa(iban, publicKey) : iban,
          bic: bic ? encryptWithRsa(bic, publicKey) : bic,
          wire_date: wireDate,
          promotion_code: promotionCode,
          coach_name :coachName,
          image_right: isImageRightChecked
        }))
        setRedirectContractPage(true)
      }
    }
    catch (err) {
      console.log('y\'a error la')
      setToggleErrorModal(true)
      setErrorMessage(err === IBAN_ERROR ? err : err.message)
    }
  }

  const displayInfoModal = () => {
    const info_text = generateImageRightText(gym_data)

    setInfoMessage(info_text)
    setToggleInfoModal(true)
  }

  const isBicNecessary = (iban_value) => {
    if (iban_value.length >= 2 && iban_value.charAt(0).toLocaleLowerCase() + iban_value.charAt(1).toLocaleLowerCase() !== 'fr') {
      setIsBic(true)
    }
    else {
      setIsBic(false)
    }
  }

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      {redirectContractPage && <Redirect push to="/contract_page" />}
      <Navbar />
      <Hero title={`Compléter le formulaire pour poursuivre votre inscriptipon.`} />
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <form onSubmit={(e) => handleSubmit(e)} className="px-6 mx-6 box py-6 my-6">
              <label className="label has-text-black">{STARTING_DATE}</label>
              <ConfigureDatePicker startDate={startingDate} setStartDate={setStartingDate} modalTitle={CREATE_ACCOUNT} />
              <br />
              {!simpleForm && 
              <>
                <div className="fields">
                  <label className="label has-text-black">{IBAN}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="text" placeholder={IBAN} name={IBAN} value={iban} onChange={(e) => {
                      setIban(e.target.value)
                      isBicNecessary(e.target.value)
                      }} />
                    <span className="icon is-small is-left">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
                {
                  isBic
                  ?
                  <>
                  <br />
                  <div className="fields">
                    <label className="label has-text-black">{BIC}</label>
                    <div className="control has-icons-left">
                      <input className="input" type="text" placeholder={BIC} name={BIC} value={bic} onChange={(e) => setBic(e.target.value)} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-university"></i>
                      </span>
                    </div>
                  </div>
                  </>
                :
                  <></>
                }
                <br />
                <label className="label has-text-black">{WIRE_DATE}</label><div className="control has-icons-left has-icons-right">
                  <div className="select is-normal">
                    <select name={WIRE_DATE} onChange={(e) => setWireDate(e.target.value)} required>
                      <option></option>
                      <option value="1">Tous les 1er du mois</option>
                      <option value="5">Tous les 5 du mois</option>
                    </select>
                  </div>
                  <div className="icon is-small is-left">
                    <i className="fas fa-calendar"></i>
                  </div>
                </div>
                <br />
              </>}
              <div className="fields">
                <label className="label has-text-black">{PROMOTION_CODE}</label>
                <div className="control has-icons-left">
                  <input className="input" type="text" placeholder={PROMOTION_CODE} name={PROMOTION_CODE} value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-barcode"></i>
                  </span>
                </div>
              </div>
              <br />
              <div className="fields">
                <label className="label has-text-black">{COACH_NAME}</label>
                <div className="control has-icons-left">
                  <input className="input" type="text" placeholder={COACH_NAME} name={COACH_NAME} value={coachName} onChange={(e) => setCoachName(e.target.value)} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-running"></i>
                  </span>
                </div>
              </div>
              <br />
              <div className="fields">
                <label className="checkbox has-text-weight-bold" onClick={(e) => {
                  if (e.target.checked) {
                    setIsImageRightChecked(true)
                  }
                  else {
                    setIsImageRightChecked(false)
                  }
                }}>
                  <input type="checkbox" value={true} id="image_right" />
                  {' '+IMAGE_RIGHT}
                </label>
              </div>
              <div className='has-text-weight-bold has-text-info' onClick={() => displayInfoModal()}>{KNOW_MORE}</div>
              <br />
              <div className="control">
                <button type="submit" className="button is-success is-fullwidth" value="Valider">{VALIDATE}</button>
              </div>
              <br />
            </form>
            <ErrorModal />
            <InfoModal />
          </div>
        </div>      
      <Footer />
    </section>
  )
}

export default CompleteSubscription