import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gym_id: null,
  gym_data: null,
  gym_activities: null
};

export const gymSlice = createSlice({
  name: 'gym',
  initialState,
  reducers: {
    setGymId: (state, action) => {
      state.gym_id = action.payload;
    },
    setGymData: (state, action) => {
      state.gym_data = action.payload
    },
    setGymActivities: (state, action) => {
      state.gym_activities = action.payload
    },
    resetGym: (state) => {
      state.gym_id = null;
      state.gym_data = null;
      state.gym_activities = null;
    }
  }
});

export const selectGymData = (state) => state.gym.gym_data;
export const selectGymActivities = (state) => state.gym.gym_activities

export const { setGymId, setGymData, setGymActivities, resetGym } = gymSlice.actions;

export default gymSlice.reducer;
