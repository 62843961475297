const NavbarLogin = () => {
  return (
    <nav className="is-transparent">
      <div className="navbar-brand">
        <div className="navbar-item">
          <img src="https://imageslsdt.s3.eu-west-3.amazonaws.com/LSDT_logo_definitif.png" alt="logo LSDT composé des initiales" width="112" height="28" />
        </div>
      </div>
    </nav>
  )
}

export default NavbarLogin