import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';

import { FaHourglassHalf } from "react-icons/fa"
import { Redirect } from "react-router-dom";

import { SELECT } from "../utils/text/button_titles";
import fetchFormulas from "../utils/fetch_formulas";
import { CHOOSE_SUBSCRIPTION, NO_SUBSCRIPTION } from "../utils/text/various_messages";
import { setFormula } from "../features/formulas/formulaSlice";
import { TEN_SESSIONS, UNIQUE_SESSION, TRIAL } from "../utils/text/payment_parameters";

import UseMultipleSelector from '../hooks/Use_multiple_selector';

const Default = ({gym_data, access_token}) => {
  const [formulasList, setFormulasList] = useState(null)
  const [formulasHtml, setFormulasHtml] = useState(null)
  const [redirectionPage, setRedirectionPage] = useState(null)
  const [trialSession, setTrialSession] = useState(false)

  const [user_subscription, , , , , , next_appointments, past_appointments] = UseMultipleSelector()

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      try {
        if (gym_data && access_token) {
          const formulas_list = await fetchFormulas(access_token, gym_data.id)

          setFormulasList(formulas_list)
        }
      }
      catch (err) {
        console.log(err);
      }
    })()
  }, [gym_data, access_token])

  useEffect(() => {
    if (formulasList) {
      displayFormulaList(formulasList)
    }
  }, [formulasList])

  const selectFormula = (formula) => {
    localStorage.setItem('selected_formula_id', formula.id)
    dispatch(setFormula(formula))
    switch (formula.type) {
      case '10_sessions':
        setRedirectionPage(`/payment_component?payment_parameter=${TEN_SESSIONS}`)
        break;
      case UNIQUE_SESSION:
        setRedirectionPage('/booking_unique_session')
        break;
      case TRIAL:
        setRedirectionPage('/booking_trial_session')
        break;
      default:
        setRedirectionPage('/complete_subscription')
    }
  }

  // const displayTrialSession = () => {
  // if (next_appointments && past_appointments && user_subscription) {
  //   console.log('next_appointments.length ', next_appointments.length)
  //   console.log('past_appointments.length ', past_appointments.length)
  //   // console.log('user_subscription ', user_subscription)

  //   if (next_appointments.length === 0 && past_appointments.length === 0 && user_subscription.Formula.type === 'guest') {
  //     setTrialSession(true)
  //   }
  // }
  // }

  useEffect(() => {
    if (next_appointments && past_appointments && user_subscription) {
      if (next_appointments.length === 0 && past_appointments.length === 0 && user_subscription.Formula.type === 'guest') {
        setTrialSession(true)
      }
    }
  }, [next_appointments, past_appointments, user_subscription])


  const displayFormulaList = (formula_list) => {
    const list = formula_list.map((formula, index) => {
      if (!trialSession && formula.type === 'trial') {
        return <></>
      }
      else {
        return (<div className="box my-4 mx-4" key={index}>
        <div className="title is-4">{formula.formula}</div>
        <div className="subtitle is-5">{formula.price}</div>
        <div className="content">{formula.detail}</div>
        <div className="has-text-centered">
          <button className="button is-fullwidth is-success" onClick={() => selectFormula(formula)}>{SELECT}</button>
        </div>
      </div>
    )
      }
    })

    return setFormulasHtml(list)
  }
  
  return (
    <>
      {redirectionPage && <Redirect push to={redirectionPage} />}
      <center>
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="box mx-6 my-3">
              <FaHourglassHalf color="#4c994a" size="8em" />
              <p className="title is-4 py-5">{NO_SUBSCRIPTION}</p>
              <p className="subtitle is-5">{CHOOSE_SUBSCRIPTION}</p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            {formulasHtml}
          </div>
        </div>
      </center>
    </>
  )
}

export default Default;