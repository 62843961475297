import { FaBan } from "react-icons/fa";

const Suspended = (props) => {
  return (
    <center>
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="box mx-6 my-6">
            <FaBan color="#F24767" size="8em" />
            <p className="title is-4 py-5">Votre compte est suspendu</p>
            <p className="subtitle is-5">Veuillez prendre contact avec le responsable de la LA SALLE DU TEMPS</p>
            <a href={`tel:+33${props.gym_data.phone_number}`}>
              <p className="subtitle is-6 pb-2">
              0{props.gym_data.phone_number}
              </p>
            </a>
            <a href={`mailto:${props.gym_data.administrator_email}}`}>
              <p className="subtitle is-6">
                {props.gym_data.administrator_email}
              </p>
            </a>
          </div>
        </div>
      </div>
    </center>
  )
}

export default Suspended;