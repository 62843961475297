import { url_api } from "./config";

const recordAppointment = async (token, user_id, gym_id, date, activity, session) => {
  try {
    const fetch_appointment = await fetch(`${url_api}/appointments/users/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        date: date,
        activity: activity,
        hour: session,
        user_id: user_id,
        gym_id: gym_id
      }),
      mode: 'cors'
    })

    if (fetch_appointment.status !== 204) {
      await fetch_appointment.json()
    }
    return
  }
  catch (err) {
    throw err
  }
}

export default recordAppointment