import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setToken } from '../features/token/tokenSlice'

const UseRedirection = () => {
  const [redirection, setRedirection] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const access_token = localStorage.getItem('access_token')
    const user_id = localStorage.getItem('user_id')
    const gym_id = localStorage.getItem('gym_id')


    if (!access_token || !user_id || !gym_id) {
      localStorage.clear()
      return setRedirection(true)
    }
    dispatch(setToken(access_token))
  }, [redirection])
  return redirection;
}

export default UseRedirection;