
import { url_api } from "./config";

const fetchReferralCode = async (access_token, user_id) => {
  try {
    const get_referral_code = await fetch(`${url_api}/promotion_code/users/${user_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      mode: 'cors'
    })
    const referral_code = await get_referral_code.json();

    return referral_code
  }
  catch (err) {
    throw err
  }
}

export default fetchReferralCode