import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Redirect } from 'react-router-dom'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'

import UseStartingData from '../hooks/Use_starting_data'
import UseUserIds from '../hooks/Use_user_ids'
import UseErrorModal from '../hooks/Use_error_modal'
import UseRedirection from '../hooks/Use_redirection'

import { setUserIban } from '../features/user/userSlice'

import checkIban from '../utils/check_iban'
import { VALIDATE } from '../utils/text/button_titles'
import { BIC, IBAN } from '../utils/text/various_messages'
import fetchPublicKey from '../utils/fetch_public_key'
import encryptWithRsa from '../utils/encrypt_with_rsa'
import { IBAN_ERROR, BIC_ABSENCE } from '../utils/text/error_messages'


function CompleteAuthorization() {
  UseStartingData()
  
  const [iban, setIban] = useState('')
  const [publicKey, setPublicKey] = useState(null)
  const [redirectAuthorizationPage, setRedirectAuthorizationPage] = useState(false)
  const [isBic, setIsBic] = useState(null)
  const [bic, setBic] = useState('')

  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()

  const [acccess_token, , user_id] = UseUserIds()
  const redirection = UseRedirection()

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      try {
        const public_key = await fetchPublicKey(acccess_token, user_id)

        setPublicKey(public_key)
      }
      catch (err) {
        if (err.message) {
          setErrorMessage(err.message)
        }
        setToggleErrorModal(true)
      }
    })()
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (isBic && !bic) {
        throw new Error(BIC_ABSENCE)
      }
      const encrypted_iban = encryptWithRsa(iban, publicKey)
      const encrypted_bic = isBic ? encryptWithRsa(bic, publicKey) : null
      const check_iban = await checkIban(acccess_token, user_id, encrypted_iban, encrypted_bic)

      if (check_iban === IBAN_ERROR) {
        throw check_iban
      }
      dispatch(setUserIban({
        iban: encryptWithRsa(iban, publicKey),
        bic: bic ? encryptWithRsa(bic, publicKey) : null
      }))
      setRedirectAuthorizationPage(true)
    }
    catch (err) {
      setToggleErrorModal(true)
      setErrorMessage(err === IBAN_ERROR ? err : err.message)
    }
  }

  const isBicNecessary = (iban_value) => {
    if (iban_value.length >= 2 && iban_value.charAt(0).toLocaleLowerCase() + iban_value.charAt(1).toLocaleLowerCase() !== 'fr') {
      setIsBic(true)
    }
    else {
      setIsBic(false)
    }
  }

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      {redirectAuthorizationPage && <Redirect push to="/authorization_page" />}
      <Navbar />
      <Hero title={`Compléter le formulaire pour poursuivre votre inscriptipon.`} />
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <form onSubmit={(e) => handleSubmit(e)} className="px-6 mx-6 box py-6 my-6">
                <div className="fields">
                  <label className="label has-text-black">{IBAN}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="text" placeholder={IBAN} name={IBAN} value={iban} onChange={(e) => {
                      setIban(e.target.value)
                      isBicNecessary(e.target.value)
                    }} required />
                    <span className="icon is-small is-left">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
                {
                  isBic
                  ?
                  <>
                  <br />
                  <div className="fields">
                    <label className="label has-text-black">{BIC}</label>
                    <div className="control has-icons-left">
                      <input className="input" type="text" placeholder={BIC} name={BIC} value={bic} onChange={(e) => {
                        setBic(e.target.value)
                      }} required />
                      <span className="icon is-small is-left">
                        <i className="fas fa-university"></i>
                      </span>
                    </div>
                  </div>
                  </>
                  :
                  <></>
                }
              <br />
              <div className="control">
                <button type="submit" className="button is-success is-fullwidth" value="Valider">{VALIDATE}</button>
              </div>
              <br />
            </form>
            <ErrorModal />
          </div>
        </div>      
      <Footer />
    </section>
  )
}

export default CompleteAuthorization