export const INFORMATION_RETRIEVAL = `Récupération des informations`
export const PASSWORD = `Mot de passe`
export const EMAIL = `Email`
export const FILL_IN_THE_FORM = `Compléter le formulaire pour enregistrer votre séance.`
export const SELECT_THE_DATE = `Choisissez la date`
export const SELECT_THE_ACTIVITY = `Choisissez votre activité`
export const SELECT_A_SLOT = `Choisissez parmis nos créneaux disponibles`
export const NO_MORE_SLOTS = `Nous n'avons plus de créneaux disponibles pour cette date`
export const CREATE_ACCOUNT = "Créer votre compte à LA SALLE DU TEMPS"
export const LAST_NAME = 'Nom'
export const FIRST_NAME = 'Prénom'
export const GENDER = 'Sexe'
export const MALE = 'Homme'
export const FEMALE = 'Femme'
export const ADRESS = 'Adresse'
export const ZIP_CODE = 'Code postale'
export const CITY = 'Ville'
export const MOBILE = 'Numéro de mobile'
export const BIRTH_DATE = 'Date de naissance'
export const CONFIRM_PASSWORD = 'Confirmation du mot de passe'
export const PASSWORD_INFORMATIONS = 'Le "Mot de passe" doit contenir au minimum 8 caractères dont 1 minuscule, 1 majuscule et 1 chiffre. Merci de renouveler votre saisie.'
export const WAITING_TEXT = `Merci de patienter quelques instants`
export const SELECT_GYM = `Veuilles selectionner votre LA SALLE DU TEMPS`
export const NO_SUBSCRIPTION = `Vous n'avez pas d'abonnement actif.`
export const CHOOSE_SUBSCRIPTION = `Choisissez l'une de nos formules et commencez votre transformation.`
export const IBAN = 'IBAN'
export const STARTING_DATE = `Date de début d'abonnement`
export const COACH_NAME = `Nom du coach`
export const WIRE_DATE = `Date de prélévement`
export const PROMOTION_CODE = `Code promo`
export const IMAGE_RIGHT = `Droit à l'image: J’autorise la Salle du Temps SAS à utiliser des photos et vid...`
export const KNOW_MORE = `En savoir plus`
export const SIGNATURE_STATUS_IN_PROGRESS = `Il ne vous reste plus qu'à signer votre contrat d'abonnement et/ou votre autorisation de prélèvement avec\r LA SALLE DU TEMPS\r pour commencer votre entraînement dès aujourd'hui.`
export const SIGNATURE_STATUS_REFUSED = `Nous sommes désolés que vous ne souhaitiez pas poursuivre votre inscription.\r N'hésitez pas à venir nous rencontrer sur place.\r A très bientôt`
export const AMOUNT_TO_PAY = `Montant à régler`
export const SECURE_PAYMENT = `Paiement sécurisé par notre partenaire STRIPE`
export const INSERT_EMAIL = `Renseignez votre adresse email et réinitialisez votre mot de passe.`
export const INSERT_EMAIL_LEGEND = `Insérer l'email à réinitialiser`
export const PASSWORD_CHANGED = `Vous venez de recevoir votre nouveau mot de passe par email.`
export const REFERRAL_CODE = `Code parrainage`
export const COPY_LEGEND = `Copier le code en cliquant sur le bouton`
export const PROFILE_TITLE = `Retrouver ici vos informations pratiques`
export const CONTACT = `Contact`
export const CHANGE_YOUR_INFORMATIONS = `Ici, vous pouvez modifier une ou plusieurs de vos informations`
export const UPDATE_CONFIRMED = `Les modifications ont bien été prises en compte`
export const UNIQUE_SESSION_RECORDED = `Votre séance a correctement été enregistrée. Vous allez recevoir votre facture par email.`
export const SUBSCRIPTION_RECORDED = `Félicitations, votre inscription a bien été enregistrée ! Vous allez recevoir votre facture par email.`
export const BIC = 'BIC'
export const INDICATIF = 'Indicatif'