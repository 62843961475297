import { url_api } from "./config";

const fetchAppointments = async (token, user_id) => {
  const fetch_appointments = await fetch(`${url_api}/appointments/users/${user_id}/app`,
    {
      method:'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    },
  );
  const appointments = await fetch_appointments.json()

  return [appointments.next_appointments, appointments.past_appointments]
}

export default fetchAppointments