const Footer = () => {
  return (
    <footer className="footer has-background-light">
      <div className="content has-text-centered">
        <p>
          <strong>LA SALLE DU TEMPS</strong>
          <br />
          <strong>2021</strong>
        </p>
      </div>
    </footer>
  )
}

export default Footer;