import { useEffect } from "react";

import { useDispatch } from 'react-redux'

import fetchAppointments from "../utils/fetch_appointments";
import fetchGymActivities from "../utils/fetch_gym_activities";
import fetchUserData from "../utils/fetch_user_data";
import fetchGymData from "../utils/fetch_gym_data";
import { setToken } from "../features/token/tokenSlice";
import { setGymActivities, setGymData } from "../features/gym/gymSlice";
import { setUserData, setUserSession, setUserSubscription } from "../features/user/userSlice";
import { setNextAppointments, setPastAppointments } from "../features/appointments/appointmentSlice";

const UseStartingData = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('access_token');
      const gym_id = localStorage.getItem('gym_id');
      const user_id = localStorage.getItem('user_id')

      if (token && gym_id && user_id) {
        const [next_appointments, past_appointments] = await fetchAppointments(token, user_id)
        const gym_activities = await fetchGymActivities(token, gym_id)
        const [user_data, user_sessions, user_subscription] = await fetchUserData(token, user_id)
        const gym_data = await fetchGymData(token, gym_id, user_id)        

        delete gym_data.stripe_public_key
        if (user_subscription.Contracts && user_subscription.Contracts[0]) {
          localStorage.setItem('contract_id', user_subscription.Contracts[0].id)
        }
        dispatch(setToken(token))
        dispatch(setGymActivities(gym_activities))
        dispatch(setGymData(gym_data))
        dispatch(setUserData(user_data))
        dispatch(setUserSession(user_sessions))
        dispatch(setUserSubscription(user_subscription))
        dispatch(setNextAppointments(next_appointments))
        dispatch(setPastAppointments(past_appointments))
      }
    })()
  }, [])
}

export default UseStartingData