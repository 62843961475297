import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { Redirect } from "react-router-dom"
import dayjs from 'dayjs'

import { setUniqueSession, setTrialSession } from "../features/appointments/appointmentSlice"

import ConfigureDatePicker from './Configure_date_picker'

import fetchSessions from '../utils/fetch_sessions'
import recordAppointment from '../utils/record_appointment'
import updateAppointment from "../utils/update_appointment"
import { ERROR, AN_ERROR_HAPPENED, SLOT_IS_FULL, SLOT_HAS_BEEN_TAKEN } from '../utils/text/error_messages'
import { FILL_IN_THE_FORM, SELECT_THE_DATE, SELECT_THE_ACTIVITY, SELECT_A_SLOT, NO_MORE_SLOTS } from '../utils/text/various_messages'
import { VALIDATE, OK } from '../utils/text/button_titles'
import { SUMMARY_OF_YOUR_SESSION, CONGRATS } from '../utils/text/modal_titles'
import { UNIQUE_SESSION, TRIAL } from "../utils/text/payment_parameters"


const BookingForm = (props) => {
  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState(new Date())
  const [activity, setActivity] = useState(null)
  const [sessions, setSessions] = useState(null)
  const [session, setSession] = useState(null)
  const [isModalActive, activeModal] = useState(null)
  const [isSuccessModalActive, activeSuccessModal] = useState(null)
  const [isErrorModalActive, activeErrorModal] = useState(null)
  const [successModalMessage, setSuccessModalMessage] = useState(null)
  const [errorModalMessage ,setErrorModalMessage] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [paymentParameter, setPaymentParameter] = useState(UNIQUE_SESSION)

  useEffect(() => {
    if (activity !== null) {
      (async () => {
        try {
          const new_sessions = await fetchSessions(props.access_token, props.user_data.id, props.gym_data.id, dayjs(startDate).format('DD-MM-YYYY'), activity, props.modalTitle ? true : false, props.initialAppointment)

          setSessions(new_sessions)
        }
        catch (err) {
          console.log(err)
        }
      })()
    }
  }, [activity, startDate])

  const activities = props.gym_activities ?
    props.gym_activities.map((activity, index) => {
      return <option id={activity.id} key={index}>{activity.db_name}</option>
    })
  : null
  const new_sessions = sessions ? sessions.map((session, index) => {
    return <option key={index}>{session}</option>
  }) : []

  const clearForm = () => {
    document.getElementById('booking_form').reset()
  }

  return (
    <>
      {redirect && <Redirect push to={`/payment_component?payment_parameter=${paymentParameter}`} />}
      <div className="columns is-vcentered">
          <div className="column is-half is-offset-3">
            <div className="subtitle has-text-centered mx-3 mt-5">
              <p className="has-text-black is-family-secondary has-text-weight-bold">{props.modalTitle ? props.modalTitle : FILL_IN_THE_FORM}</p>
            </div>
            <div className={props.modalTitle ? "box" : "box mx-6 my-6"}>
              <form className="fields" id="booking_form">
              <label className="label">{SELECT_THE_DATE}</label>
              <ConfigureDatePicker startDate={startDate} setStartDate={setStartDate} modalTitle={props.modalTitle} />
              <br />
              <label className="label">{SELECT_THE_ACTIVITY}</label>
              <div className="control has-icons-left has-icons-right">
                <div className="select is-normal">
                  <select name="activity" required onChange={(e) => {
                    setActivity(e.target.value)
                    }}>
                    <option></option>
                    {activities}
                  </select>
                </div>
                <div className="icon is-small is-left">
                  <i className="fas fa-dumbbell"></i>
                </div>
              </div>
              <br/>
              <label className="label">{SELECT_A_SLOT}</label>
                <div className="control has-icons-left has-icon-right">
                  <div className="select is-normal mb-4">
                    {
                      activity !== null
                      ?
                      <select name="hour" required onChange={(e) => setSession(e.target.value)}>
                        <option></option>
                        {new_sessions}
                      </select>
                      :
                      <select name="hour" required disabled>
                        <option></option>
                      </select>
                    }
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fas fa-clock"></i>
                  </span>
                </div>
                <br />
                <div className="control">
                  {
                    session !== null && activity !== null
                    ?
                    (<button 
                      type='submit'
                      className="button is-success is-fullwidth"
                      onClick={(e) => {
                        e.preventDefault()
                        activeModal(true)
                      }}>{VALIDATE}</button>)
                    :
                    (<button type='submit' className="button is-success is-fullwidth" disabled>{VALIDATE}</button>)
                  }
                </div>
                  {
                    sessions !== null && sessions.length === 0 && activity !== null
                      ?
                      <div className="has-text-danger has-text-weight-bold has-text-centered">{NO_MORE_SLOTS}</div>
                      :
                      <div></div>
                  }
              </form>
            </div>
          </div>
        </div>

        <div className={isModalActive ? 'modal is-active' : 'modal'}>
          <div className="modal-background" />
          <div className="modal-card px-6">
            <header className="modal-card-head">
              <div className="container">
                <p className="modal-card-title">{SUMMARY_OF_YOUR_SESSION}</p>
              </div>
              <button className="delete" aria-label="close" onClick={() => activeModal(false)} />
            </header>
            <section className="modal-card-body">
              <div className="content">
                <ul>
                  <li className="subtitle is-6">
                    {`Date: ${dayjs(startDate).format('DD/MM/YYYY')}`}
                  </li>
                  <li className="subtitle is-6">
                    {`Activité: ${activity}`}
                  </li>
                  <li className="subtitle is-6">
                    {`Heure: ${session}`}
                  </li>
                </ul>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-fullwidth" onClick={async () => {
                try {
                  console.log()
                  console.log('props.isUniqueSession ===> ', props.isUniqueSession)
                  if (props.modalTitle) {
                    await updateAppointment(props.access_token, props.user_data.id, props.gym_data.id, props.appointment_id, activity, session, dayjs(startDate).format('DD-MM-YYYY'))  
                  }
                  else if (props.isUniqueSession) {
                    setPaymentParameter(UNIQUE_SESSION)
                    const unique_session = {
                      date: dayjs(startDate).format('DD-MM-YYYY'),
                      activity: activity,
                      hour: session,
                    }
                    dispatch(setUniqueSession(unique_session))
                    localStorage.setItem(UNIQUE_SESSION, JSON.stringify(unique_session))
                  }
                  else if (props.isTrialSession) {
                    setPaymentParameter(TRIAL)
                    const trial_session = {
                      date: dayjs(startDate).format('DD-MM-YYYY'),
                      activity: activity,
                      hour: session,
                    }
                    dispatch(setTrialSession(trial_session))
                    localStorage.setItem(TRIAL, JSON.stringify(trial_session))
                  }
                  else {
                    await recordAppointment(props.access_token, props.user_data.id, props.gym_data.id, dayjs(startDate).format('DD-MM-YYYY'), activity, session)  
                  }
                  if (props.isUniqueSession) {
                    return setRedirect(true)
                  }
                  if (props.isTrialSession) {
                    return setRedirect(true)
                  }
                  setSuccessModalMessage('Votre séance a correctement été enregistrée !')
                  activeModal(false)
                  activeSuccessModal(true)
                }
                catch (err) {
                  console.log(err)
                  activeModal(false)
                  activeErrorModal(true)
                  if (err.response && err.response.data === SLOT_IS_FULL) {
                    setErrorModalMessage(SLOT_HAS_BEEN_TAKEN)
                  }
                  else {
                    setErrorModalMessage(AN_ERROR_HAPPENED)
                  } 
                }
              }}>Confirmer</button>
            </footer>
          </div>
        </div>

        <div className={isSuccessModalActive ? 'modal is-active' : 'modal'}>
          <div className="modal-background" />
          <div className="modal-card px-6">
            <header className="modal-card-head has-background-success has-text-white">
              <p className="modal-card-title has-text-white">{CONGRATS}</p>
              <button className="delete" aria-label="close" onClick={() => activeSuccessModal(false)} />
            </header>
            <section className="modal-card-body">
              <h4 className="subtitle ">{successModalMessage}</h4>
              <br />
              <div className="has-text-centered">
                <button className="button is-fullwidth" onClick={() => {
                  activeSuccessModal(false)
                  setSession(null)
                  setActivity(null)
                  setStartDate(new Date())
                  clearForm()
                  return window.location.href = '/appointments'
                  }}>{OK}</button>
              </div>
            </section>
          </div>
        </div>

        <div className={isErrorModalActive ? 'modal is-active' : 'modal'}>
            <div className="modal-background" />
            <div className="modal-card px-6">
              <header className="modal-card-head has-background-danger has-text-white">
                <p className="modal-card-title has-text-white">{ERROR}</p>
                <button className="delete" aria-label="close" onClick={() => activeErrorModal(false)} />
              </header>
              <section className="modal-card-body">
                <h4 className="sutitle">{errorModalMessage}</h4>
                <br />
                <div className="has-text-centered">
                  <button className="button is-fullwidth" onClick={() => activeErrorModal(false)}>{OK}</button>
                </div>
              </section>
            </div>
          </div>
      </>
  )
}

export default BookingForm