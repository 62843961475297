import { JSEncrypt } from 'jsencrypt';

const encryptWithRsa = (IBAN, publick_key) => {  
  const jsEncrypt = new JSEncrypt();

  jsEncrypt.setPublicKey(publick_key);
  const encrypted_iban = jsEncrypt.encrypt(IBAN)

  return encrypted_iban;
}

export default encryptWithRsa