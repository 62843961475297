import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"

import { Redirect } from 'react-router-dom'

import UseErrorModal from '../hooks/Use_error_modal'

import { selectTrialToken } from '../features/token/tokenSlice'
import { selectUserCreation } from '../features/user/userSlice'

import Navbar from '../items/Navbar'
import Hero from '../items/Hero'
import fetchGymList from '../utils/fetch_gym_list'
import { SELECT_GYM } from '../utils/text/various_messages'
import GymCell from '../items/Gym_cell'
import SubscribeGuestUser from '../utils/Subscribe_guest_user'

function SelectGym() {
  const [redirection, setRedirection] = useState(false)
  const [gymList, setGymList] = useState(null)
  const trial_token = useSelector(selectTrialToken)
  const user_creation_data = useSelector(selectUserCreation)

  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()

  useEffect(() => {
    if (!trial_token || !user_creation_data) {
      setRedirection(true)
    }
  }, [trial_token, user_creation_data])

  useEffect(() => {
    try {
      (async () => {
        const gym_list = await fetchGymList(trial_token)

        setGymList(gym_list)
      })()
    }
    catch (err) {
      console.log(err)
    }
  }, [])

  const handleClick = async (gym_id) => {
    try {
      const record_user = await SubscribeGuestUser(user_creation_data, gym_id, trial_token)

      if (typeof record_user === 'string') {
        throw record_user
      }
      const user_data = record_user

      // if (typeof user_data === 'string') {
      //   throw record_user
      // }
      localStorage.setItem('user_id', user_data.user_id)
      localStorage.setItem('access_token', user_data.access_token)
      localStorage.setItem('gym_id', user_data.gym_id)
      localStorage.setItem('formula_id', user_data.user_subscription.Formula.id)
      setRedirection(true)
    }
    catch (err) {
      if (typeof err === 'string') {
        setErrorMessage(err)
      }
      setToggleErrorModal(true)
    }
  }

  const gym_list_2 = gymList ? gymList.map((gym, index) => {
    return GymCell(gym, index, handleClick)
  }) : []

  return (
    <>
      {redirection && <Redirect to="/"/>}
      <Navbar />
      <Hero title={SELECT_GYM} />
      <div className='columns'>
        <div className="column is-6 is-offset-3" >
          {gym_list_2}
        </div>
      </div>
      <ErrorModal />
    </>
  );
}

export default SelectGym