
import { url_api } from "./config";

const fetchMessage = async (gym_id) => {
  try {
    const get_gym_message = await fetch(`${url_api}/messages?gym_id=${gym_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      mode: 'cors'
    })
    const gym_message = await get_gym_message.json();

    console.log('gym_message inside the fetch ', gym_message)
    return gym_message
  }
  catch (err) {
    throw err
  }
}

export default fetchMessage