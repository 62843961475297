import { NEXT_APPOINTMENTS, PAST_APPOINTMENTS } from "../utils/text/link_title"

const Tabs = (props) => {
  return (
    <center className="tabs is-centered is-boxed is-fullwidth is-primary">
      <ul>
        <li className={props.selectedTab === NEXT_APPOINTMENTS ? "is-active" : ""} onClick={() => props.selectTab(NEXT_APPOINTMENTS)}>
          <a>
            <span className="icon">
              <i className="fa fa-dumbbell"></i>
            </span>
            <span>
              {NEXT_APPOINTMENTS}
            </span>
          </a>
        </li>
        <li className={props.selectedTab === PAST_APPOINTMENTS ? "is-active" : ""} onClick={() => props.selectTab(PAST_APPOINTMENTS)}>
          <a>
            <span className="icon">
              <i className="fa fa-calendar-check"></i>
            </span>
            <span>
              {PAST_APPOINTMENTS}
            </span>
          </a>
        </li>
      </ul>
    </center>
  )
}

export default Tabs