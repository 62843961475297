import { url_api } from "./config";

const fetchContractData = async (token, contract_id) => {
  try {
    const fetch_contract_data = await fetch(`${url_api}/contracts/ids/${contract_id}`,
      {
        method:'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        mode: 'cors',
      },
    );
    const contract_data = await fetch_contract_data.json()

    return contract_data
  }
  catch (err) {
    throw err
  }
}

export default fetchContractData