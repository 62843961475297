import { url_api } from "./config";

const fetchSessions = async (token, user_id, gym_id, date, activity, is_an_update = false, initial_appointmentds = null) => {
  try {
    console.log('is_an_update => ', is_an_update)
    console.log('initial_appointmentds => ', initial_appointmentds)

    const fetch_sessions = await fetch(`${url_api}/appointments/gyms/${gym_id}/dates/${date}/activities/${activity}/users/${user_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        isAnUpdate: is_an_update,
        appointmentToUpdate: JSON.stringify(initial_appointmentds)
      },
      mode: 'cors'
    });
    const sessions = await fetch_sessions.json();
    
    return sessions;
  }
  catch (err) {
    throw err
  }
}

export default fetchSessions;