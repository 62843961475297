import { url_api } from "./config";

const deleteAppointment = async (token, user_id, appointment_id, subscription_type) => {
  try {
    const delete_appointment = await fetch(`${url_api}/appointments/app`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        appointment_id: appointment_id,
        user_id: user_id,
        subscription_type: subscription_type,
      }),
    })

    if (delete_appointment.status !== 204) {
      await delete_appointment.json()
    }
  }
  catch (err) {
    throw err
  }
}

export default deleteAppointment