import { url_api } from "./config";

const checkStatusAuthorization = async (access_token, user_id) => {
  try {
    const check_status = await fetch(`${url_api}/yousign_v2/status/authorization/users/${user_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      mode: 'cors'
    })
    const status_data = await check_status.json();

    return status_data
  }
  catch (err) {
    throw err
  }
}

export default checkStatusAuthorization