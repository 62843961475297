const ErrorModal = ({toggleErrorModal, setToggleErrorModal, ERROR, OK, ERROR_MESSAGE}) => {
  return (
    <div className={toggleErrorModal ? 'modal is-active' : 'modal'}>
      <div className="modal-background" />
      <div className="modal-card px-6">
        <header className="modal-card-head has-background-danger has-text-white">
          <p className="modal-card-title has-text-white">{ERROR}</p>
          <button className="delete" aria-label="close" onClick={() => setToggleErrorModal(false)} />
        </header>
        <section className="modal-card-body">
          <h4 className="sutitle">{ERROR_MESSAGE}</h4>
          <br />
          <div className="has-text-centered">
            <button className="button is-fullwidth" onClick={() => setToggleErrorModal(false)}>{OK}</button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ErrorModal