
import { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { BsExclamationOctagon } from "react-icons/bs"
import { LONG_SUBSCRIPTION, ONE_MONTH_SUBSCRIPTION } from "../utils/text/payment_parameters"

const Uncompleted = ({user_subscription}) => {

  const [paymentParameter, setPaymentParameter] = useState(LONG_SUBSCRIPTION)

  useEffect(() => {
    let is_subscribed = true
    
    if (user_subscription && user_subscription.Formula.payment_type === 'invoice') {
      if (is_subscribed) {
        setPaymentParameter(ONE_MONTH_SUBSCRIPTION)
      }
    }
    return () => is_subscribed = false
  }, [user_subscription])

  return (
    <center>
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="box mx-6 my-6">
            <BsExclamationOctagon color="#F24767" size="8em" />
            <p className="title is-4 py-5">Finaliser votre inscription.</p>
            <p className="subtitle is-5">Il vous reste à payer votre prorata et/ou à nous communiquer votre IBAN pour réserver d'autres séances.</p>
          </div>
          <div className="box mx-6 px-6 mb-6">
            {
              !user_subscription.is_iban_provided
              &&
              <div className="has-text-centered my-3 py-3">
                <Link to="/complete_authorization">
                  <div className="button is-info is-fullwidth">Autorisation de prélèvement</div>
                </Link>
              </div>
            }
            {
              !user_subscription.is_prorata_paid
              &&
              <div className="has-text-centered my-3 py-3">
                <Link to={`/payment_component?payment_parameter=${paymentParameter}`}>
                  <div className="button is-info is-fullwidth">Prorata</div>
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
    </center>
  )
}

export default Uncompleted;