import {SUSPENDED, BREAKED, LOCKED, BLOCKED, DEFAULT, LESSON, UNCOMPLETED} from './screen_type'
import extractUserInformations from './extract_user_informations'

const selectScreen = (user_data, user_subscription, user_session) => {
  if (user_subscription === null || user_session === null) {
    return DEFAULT
  }
  const [
    subscription_status, 
    user_session_taken, 
    max_session_authorized, 
    user_sessions_blocked, 
    subscription_type, 
    is_unlimited_formula, 
    subscription_payment_type, 
    invoice_subscription_sessions_taken, 
    invoice_subscription_sessions_number, 
    is_subscription_completed, 
    is_session_opened
  ] = extractUserInformations(user_data, user_subscription, user_session)

  if (user_data.blocked) {
    return SUSPENDED
  }
  else if (subscription_status === 'suspended') {
    return BREAKED
  }
  else if (user_session_taken === max_session_authorized) {
    return LOCKED
  }
  else if (user_sessions_blocked) {
    return BLOCKED
  }
  if (subscription_type === 'guest') {
    return DEFAULT
  }
  else if (!is_unlimited_formula && subscription_payment_type === 'invoice') {
    if (invoice_subscription_sessions_taken < invoice_subscription_sessions_number) {
      return LESSON
    }
  }
  else if (!is_subscription_completed && is_session_opened) {
    return LESSON
  }
  else if (!is_subscription_completed && is_subscription_completed !== null && !is_session_opened) {
    return UNCOMPLETED
  }
  else if (is_unlimited_formula && subscription_payment_type === 'invoice') {
    return LESSON
  }
  else if (is_unlimited_formula && subscription_payment_type === 'contract') {
    return LESSON
  }
  else if (is_unlimited_formula && subscription_payment_type === 'weekly') {
    return LESSON
  }
  else {
    return DEFAULT
  }
}

export default selectScreen
