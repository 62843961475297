
const setFormOrRedirection = (selected_formula, setSimpleForm, setRedirectPayment) => {
  const payment_type = selected_formula.payment_type
  const is_unlimited = selected_formula.is_unlimited_formula

  if (payment_type === 'contract') {
    return;
  }
  else if (payment_type === 'invoice' && is_unlimited) {
    return setSimpleForm(true)
  }
  else {
    return setRedirectPayment(true)
  }
}

export default setFormOrRedirection