const UseUserIds = () => {
  const access_token = localStorage.getItem('access_token')
  const gym_id = localStorage.getItem('gym_id')
  const user_id = localStorage.getItem('user_id')

  return [
    access_token,
    gym_id,
    user_id
  ]
}

export default UseUserIds