// import {sha256} from 'react-native-sha256';
import sha256 from 'crypto-js/sha256';

const encryptPassword = async uncrypted_password => {
  try {
    const hash = await sha256(uncrypted_password);
    const sha256_password = await hash;
    const salt = 'hficHW048572CSaldwiEic9';
    const pepper = 'q2wQpsa3SsCflssmd';
    const encrypted_password = `${salt}${sha256_password}${pepper}`;

    return encrypted_password;
  } catch (err) {
    throw err;
  }
};

export default encryptPassword;
