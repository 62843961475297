import {SUSPENDED, BREAKED, LOCKED, BLOCKED, DEFAULT, LESSON, UNCOMPLETED} from '../utils/screen_type'
import Suspended from '../items/Suspended'
import Locked from '../items/Locked'
import Blocked from '../items/Blocked'
import Breaked from '../items/Breaked'
import Uncompleted from '../items/Uncompleted'
import Default from '../items/Default';
import BookingForm from '../items/Booking_form';

const selectScreenComponent = (selected_screen, gym_data, gym_activities, user_data, access_token, user_session, user_subscription) => {
  let component = ''

  switch(selected_screen) {
    case DEFAULT:
      component = <Default gym_data={gym_data} access_token={access_token} />
      break;
    case SUSPENDED:
      component = <Suspended gym_data={gym_data} />
      break;
    case LESSON:
      component = <BookingForm 
                    gym_activities={gym_activities} 
                    user_data={user_data} 
                    gym_data={gym_data} 
                    access_token={access_token} 
                    appointment_id={null} 
                    modalTitle={null}
                  />
      break;
    case BREAKED:
      component = <Breaked />
      break;
    case BLOCKED:
      component = <Blocked user_session={user_session} gym_data={gym_data} />
      break;
    case LOCKED:
      component = <Locked user_session={user_session} />
      break;
    case UNCOMPLETED:
      component = <Uncompleted user_subscription={user_subscription} />
      break;
    default:
      component = <Default />
  }
  return component
}

export default selectScreenComponent