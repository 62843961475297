import { RiLock2Line } from "react-icons/ri";

const Locked = (props) => {
  return (
    <center>
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="box mx-6 my-6">
            <RiLock2Line color="#4c994a" size="8em" />
            <p className="title is-4 py-5">Vous avez deja pris {props.user_session.max_session_authorized} cours !</p>
            <p className="subtitle is-5">Vous pourrez à nouveau réserver dès la fin de votre prochain cours.</p>
          </div>
        </div>
      </div>
    </center>
  )
}

export default Locked;