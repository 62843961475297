import { url_api } from "./config"

const postAuthorizationData = async (acccess_token, user_id, iban, bic) => {
  try {
    const post_authorization_data = await fetch(`${url_api}/yousign_v2/authorization/app/users/${user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${acccess_token}`,
      },
      body: JSON.stringify({
        encrypted_iban: iban,
        encrypted_bic: bic
      }),
      mode: 'cors'
    })
    const yousign_data = await post_authorization_data.json()

    return yousign_data
  }
  catch (err) {
    throw err
  }
}

export default postAuthorizationData