import { url_api } from './config'

const recordNewUser = async (access_token, user_id, gym_id, contract_id) => {
  try {
    await fetch(`${url_api}/subscriptions/contract_subscription/v2/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        user_id: user_id,
        gym_id: gym_id,
        contract_id: contract_id,
      }),
      mode: 'cors'
    })
  }
  catch(err) {
    throw err
  }
}

export default recordNewUser