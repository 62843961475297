import { url_api } from "./config";

const fetchSelectedFormula = async (token, formula_id) => {
  try {
    const formula = await fetch(`${url_api}/formulas/ids/${formula_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    })
    const selected_formula = await formula.json()

    return selected_formula
  } catch (err) {
      throw err
  }
}

export default fetchSelectedFormula