
import { url_api } from "./config"

const recordUserLimitedFormula = async (access_token, user_id, gym_id, selected_formula) => {
  try {
    const confirm_payment = await fetch(`${url_api}/subscriptions/app/v2/invoice/payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        formula_type: selected_formula.type,
        formula_id: selected_formula.id,
        user_id: user_id,
        gym_id: gym_id,
        description: selected_formula.formula,
      })
    })
    const payment_confirmation = await confirm_payment.text()

    if (JSON.parse(payment_confirmation) && JSON.parse(payment_confirmation).message === 'Le paiement a correctement été enregistré') {
      return 'Félicitations, votre inscription a bien été enregistrée ! Vous allez recevoir votre facture par email.' 
    }
    else {
      throw new Error(`Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.`)
    }
  }
  catch (err) {
    throw err
  }
}

export default recordUserLimitedFormula