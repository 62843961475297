import { url_api } from "./config";

const checkIban = async (token, user_id, iban, bic) => {
  try {
    const check_iban = await fetch(`${url_api}/subscriptions/check_iban/app/users/${
      user_id
    }`,
      {
        method:'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          encrypted_iban: iban,
          encrypted_bic: bic
        }),
        mode: 'cors',
      },
    );
    const check_response = await check_iban.text()

    console.log('check_response ==> ', check_response)
    return check_response
  }
  catch (err) {
    console.log('check error iban', err)
    throw err
  }
}

export default checkIban