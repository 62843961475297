import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_id: null,
  user_data: null,
  user_subscription: null,
  user_session: null,
  user_creation: null,
  user_complementary_data: null,
  user_contract_data: null,
  user_contract_data_signed: null,
  user_unlock_account: false,
  user_iban: null,
  user_authorization_data: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload
    },
    setUserData: (state, action) => {
      state.user_data = action.payload
    },
    setUserSubscription: (state, action) => {
      state.user_subscription = action.payload
    },
    setUserSession: (state, action) => {
      state.user_session = action.payload
    },
    setUserCreation: (state, action) => {
      state.user_creation = action.payload
    },
    setUserComplementaryData: (state, action) => {
      state.user_complementary_data = action.payload
    },
    setUserContractData: (state, action) => {
      state.user_contract_data = action.payload
    },
    setUserContractDataSigned: (state, action) => {
      state.user_contract_data_signed = action.payload
    },
    setUserUnlockAccount: (state, action) => {
      state.user_unlock_account = action.paylaod
    },
    setUserIban: (state, action) => {
      state.user_iban = action.payload
    },
    setUserAuthorizationData: (state, action) => {
      state.user_authorization_data = action.payload
    },
    resetUser: (state) => {
      state.user_id = null
      state.user_data = null
      state.user_subscription = null
      state.user_session = null
      state.user_creation = null
      state.user_complementary_data = null
      state.user_contract_data = null
      state.user_contract_data_signed = null
      state.user_unlock_account = null
      state.user_iban = null
      state.user_authorization_data = null
    }
  }
});

export const selectUserId = (state) => state.user.user_id;
export const selectUserData = (state) => state.user.user_data;
export const selectUserSubscription = (state) => state.user.user_subscription;
export const selectUserSession = (state) => state.user.user_session;
export const selectUserCreation = (state) => state.user.user_creation;
export const selectUserComplementaryData = (state) => state.user.user_complementary_data;
export const selectUserContractData = (state) => state.user.user_contract_data;
export const selectUserContractDataSigned = (state) => state.user.user_contract_data_signed;
export const selectUserUnlockAccount = (state) => state.user_unlock_account;
export const selectUserIban = (state) => state.user_iban;
export const selectUserAuthorizationData = (state) => state.user_authorization_data

export const { 
  setUserId, 
  setUserData, 
  setUserSubscription, 
  setUserSession, 
  setUserCreation, 
  setUserComplementaryData, 
  setUserContractData, 
  setUserContractDataSigned,
  setUserUnlockAccount,
  setUserIban,
  setUserAuthorizationData,
  resetUser } = userSlice.actions;

export default userSlice.reducer;