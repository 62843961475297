import { useSelector } from "react-redux"

import { selectGymData, selectGymActivities } from '../features/gym/gymSlice'
import { selectUserData, selectUserSubscription, selectUserSession} from '../features/user/userSlice'
import { selectToken, selectTrialToken } from '../features/token/tokenSlice';
import { selectNextAppointments, selectPastAppointments } from '../features/appointments/appointmentSlice'

const UseMultipleSelector = () => {
  const user_subscription = useSelector(selectUserSubscription)
  const user_data = useSelector(selectUserData)
  const user_session = useSelector(selectUserSession)
  const gym_data = useSelector(selectGymData)
  const gym_activities = useSelector(selectGymActivities)
  const access_token = useSelector(selectToken)
  const next_appointments = useSelector(selectNextAppointments)
  const past_appointments = useSelector(selectPastAppointments)
  const trial_access_token = useSelector(selectTrialToken)

  return [
    user_subscription, 
    user_data, 
    user_session, 
    gym_data, 
    gym_activities, 
    access_token,
    next_appointments, 
    past_appointments,
    trial_access_token
  ]
}

export default UseMultipleSelector