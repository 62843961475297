import { useState } from "react"

import UseErrorModal from "../hooks/Use_error_modal"
import UseInfoModal from "../hooks/Use_info_modal"

import Hero from "../items/Hero"
import Footer from "../items/Footer"

import { EMAIL, INSERT_EMAIL, INSERT_EMAIL_LEGEND, PASSWORD_CHANGED } from "../utils/text/various_messages"
import { REBOOT_PASSWORD } from "../utils/text/button_titles"
import resetPassword from "../utils/reset_password"
import NavbarLogin from "../items/Navbar_login"

const ForgottenPassword = () => {
  const [email, setEmail] = useState(null)

  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const [InfoModal, setToggleInfoModal, setInfoMessage] = UseInfoModal()

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      await resetPassword(email)
      setInfoMessage(PASSWORD_CHANGED)
      setToggleInfoModal(true)
    }
    catch (err) {
      setToggleErrorModal(true)
      if (err.message) {
        setErrorMessage(err.message)
      }
    }
  }

  return (
    <section>
      <NavbarLogin />
      <Hero title={INSERT_EMAIL} />
      <div className="columns my-6 mx-6">
        <div className="column is-6 is-offset-3">
          <div className="box">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="field">
                <label className="label">{EMAIL}</label>
                <div className="control has-icons-left has-icons-right">
                  <input className="input is-medium" type="email" placeholder={EMAIL} onChange={(e) => setEmail(e.target.value.toLowerCase().trim())} required />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>  
                <p className="help is-info">{INSERT_EMAIL_LEGEND}</p>
              </div>

              <div className='has-text-centered'>
                {
                  email
                  ?
                  <input className='button is-success' type="submit" value={REBOOT_PASSWORD} />
                  :
                  <input className='button is-success' type="submit" value={REBOOT_PASSWORD} disabled />
                }
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <InfoModal />
      <ErrorModal />
    </section>
  )
}

export default ForgottenPassword