import { useDispatch, useSelector } from "react-redux"

import { setTrialSession, selectTrialSession } from '../features/appointments/appointmentSlice'

const UseTrialSession = () => {
  const dispatch = useDispatch()

  let trial_session = useSelector(selectTrialSession)

  if (trial_session) {
    return trial_session
  }
  else {
    trial_session = localStorage.getItem('trial')
    
    dispatch(setTrialSession(JSON.parse(trial_session)))
    return JSON.parse(trial_session)
  }
}

export default UseTrialSession