import { url_api } from "./config";

const fetchGymActivities = async (token, gym_id) => {
  const fetch_gym_activities = await fetch(`${url_api}/activities/gyms/${gym_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  });
  const gym_activities = await fetch_gym_activities.json();
  
  return gym_activities;
}

export default fetchGymActivities;