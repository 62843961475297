import React from 'react';

import UseRedirection from './hooks/Use_redirection'
import Login from './components/Login'
import Booking from './components/Booking';
import NavbarLogin from './items/Navbar_login'

import './App.css';

function App() {
  const redirection = UseRedirection()

  return (
    <>
        {
          redirection
          ?
          <div className="login-image">
            <NavbarLogin />
            <Login />
          </div>
          :
          <Booking />
        }  
    </>
  );
}

export default App;
