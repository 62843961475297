import { Redirect } from 'react-router-dom'

import UseRedirection from '../hooks/Use_redirection'
import UseMultipleSelector from '../hooks/Use_multiple_selector';
import UseStartingData from '../hooks/Use_starting_data'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import BookingForm from '../items/Booking_form';

function BookingTrialSessionPage() {
  UseStartingData()  
  const [ , user_data, , gym_data, gym_activities, access_token] = UseMultipleSelector()
  const redirection = UseRedirection()

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      <Navbar />
      <Hero title={`Sélectionnez votre rendez-vous.`} />
      <BookingForm 
        gym_activities={gym_activities} 
        user_data={user_data} 
        gym_data={gym_data} 
        access_token={access_token} 
        appointment_id={null} 
        modalTitle={null}
        isUniqueSession={false}
        isTrialSession={true}
      />
      <Footer />
    </section>
  )
}

export default BookingTrialSessionPage