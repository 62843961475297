import { url_api } from "./config"

const fetchGymList = async (trial_token) => {
  try {
    const fetch_gym_list = await fetch(`${url_api}/gyms`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${trial_token}`,
      },
      mode: 'cors',
    });
    const gym_list_data = await fetch_gym_list.json();

    return gym_list_data;
  }
  catch (err) {
    throw err
  }
}

export default fetchGymList