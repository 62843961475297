
import { PASSWORD_INFORMATIONS } from "./text/various_messages";
import { PASSWORD_CONFIRMATION_ERROR } from "./text/error_messages";

const checkPasswordFormat = (password, confirmPassword) => {
  const strong_password = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
  );

  if (!strong_password.test(password)) {
    return PASSWORD_INFORMATIONS
  }
  else if (password !== confirmPassword) {
    return PASSWORD_CONFIRMATION_ERROR
  }
  return true
}

export default checkPasswordFormat