import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import { Redirect } from 'react-router-dom'

import { setUserAuthorizationData } from '../features/user/userSlice'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import LoadSpinner from '../items/Load_spinner'

import UseErrorModal from '../hooks/Use_error_modal'
import UseUserIds from '../hooks/Use_user_ids'
import UseRedirection from '../hooks/Use_redirection'
import UseInfoModal from '../hooks/Use_info_modal'

import { AUTHORIZATION_SIGNED, SIGN_AUTHORIZATION } from '../utils/text/button_titles'
import { yousign_app_uri } from '../utils/config'
import { SIGNATURE_STATUS_IN_PROGRESS } from '../utils/text/various_messages'
import postAuthorizationData from '../utils/post_authorization_data'
import checkStatusAuthorization from '../utils/check_authorization_status'

function AuthorizationPage() {
  const dispatch = useDispatch()

  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const [InfoModal, setToggleInfoModal, setInfoMessage] = UseInfoModal()
  const redirection = UseRedirection()
  const [access_token, , user_id] = UseUserIds()

  const [yousignUrl, setYousignUrl] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const [redirectCompleteAuthorization, setRedirectCompleteAuthorization] = useState(null)
  const [redirectHome, setRedirectHome] = useState(false)

  const user_iban = useSelector(state => state.user.user_iban)

  useEffect(() => {
    (async () => {
      try {
        const yousign_data = !user_iban ? {
          member_id: localStorage.getItem('authorization_member_id')
        } : await postAuthorizationData(access_token, user_id, user_iban.iban, user_iban.bic)

        if (!yousign_data.member_id) {
          return setRedirectCompleteAuthorization(true)
        }
        dispatch(setUserAuthorizationData(yousign_data))
        localStorage.setItem('authorization_member_id', yousign_data.member_id)
        const yousign_url = `${yousign_app_uri}/procedure/sign?members=${yousign_data.member_id}`

        setIsReady(true)
        setYousignUrl(yousign_url)
      }
      catch (err) {
        console.log(err)
        setToggleErrorModal(true)
        if (err.message) {
          setErrorMessage(err.message)
        }
      }
    })()
  }, [])

  const checkSignature = async () => {
    try {
      const authorization_status = await checkStatusAuthorization(access_token, user_id)

      if (authorization_status.is_iban_provided) {
        setRedirectHome(true)
      }
      else {
        setInfoMessage(SIGNATURE_STATUS_IN_PROGRESS)
        setToggleInfoModal(true)
      }
    }
    catch (err) {
      console.log(err)
      setToggleErrorModal(true)
      if (err.message) {
        setErrorMessage(err.message)
      }
    }
  }

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      {redirectHome && <Redirect push to='/' />}
      {redirectCompleteAuthorization && <Redirect push to="/complete_authorization" />}
      <Navbar />
      <Hero title={`Consulter et signer votre autorisation de prélèvement.`} />
      {!isReady && <LoadSpinner />}
      {isReady && <div className='columns'>
        <div className='column is-6 is-offset-3'>
          <div className='has-text-centered mx-6 my-6'>
            <a className='button is-info' href={yousignUrl} target="_blank" rel="noreferrer">{SIGN_AUTHORIZATION}</a>
          </div>
        </div>
      </div>}
      {isReady && <div className='columns'>
        <div className='column is-6 is-offset-3'>
          <div className='has-text-centered mx-6 my-6'>
            <div className='button is-success' onClick={async () => await checkSignature()}>{AUTHORIZATION_SIGNED}</div>
          </div>
        </div>
      </div>}
      <ErrorModal />
      <InfoModal />
      <Footer />
    </section>
  )
}

export default AuthorizationPage