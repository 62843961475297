import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import { Redirect } from 'react-router-dom'

import { selectUserContractDataSigned, setUserContractDataSigned } from '../features/user/userSlice'

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import StripePaymentComponent from '../items/Stripe_payment_component'
import LoadSpinner from '../items/Load_spinner'

import UseErrorModal from '../hooks/Use_error_modal'
import UseUserIds from '../hooks/Use_user_ids'
import UseRedirection from '../hooks/Use_redirection'
import UseSelectedFormula from '../hooks/Use_selected_formula'
import UseUniqueSession from '../hooks/Use_unique_session'
import UseTrialSession from '../hooks/Use_trial_session'
import UseMultipleSelector from '../hooks/Use_multiple_selector'

import { AMOUNT_TO_PAY, SECURE_PAYMENT } from '../utils/text/various_messages'
import fetchContractData from "../utils/fetch_contract_data"
import getPriceInCent from '../utils/get_price_in_cent'
import { UNLOCK_ACCOUNT, UNIQUE_SESSION, LONG_SUBSCRIPTION, ONE_MONTH_SUBSCRIPTION, TEN_SESSIONS, TRIAL } from "../utils/text/payment_parameters";
import UseStartingData from '../hooks/Use_starting_data'

function PaymentComponent() {
  const dispatch = useDispatch()
  UseStartingData()
  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const redirection = UseRedirection()
  const [access_token, gym_id, ] = UseUserIds()

  const [redirectHome, setRedirectHome] = useState(false)
  const [price, setPrice] = useState(null)
  const [priceInCent, setPriceInCent] = useState(null)
  const [paymentParameter, setPaymentParameter] = useState(null)
  const [isCardForgotten, setIsCardForgotten] = useState(false)

  const selected_formula = UseSelectedFormula()
  const unique_session = UseUniqueSession()
  const trial_session = UseTrialSession()
  const [, , , gym_data, , ] = UseMultipleSelector()

  useEffect(() => {
    (async () => {
      try {
        if (selected_formula && gym_data) {
          const formula_price = selected_formula.price_number
          const query = new URLSearchParams(window.location.search)
    
          if (query.get('payment_parameter') === UNLOCK_ACCOUNT) {
            const unlock_account_price = gym_data.unlock_account_price
                
            setPrice(unlock_account_price)
            setPriceInCent(getPriceInCent(unlock_account_price))
            setPaymentParameter(UNLOCK_ACCOUNT)
            setIsCardForgotten(false)
          }
          else if (query.get('payment_parameter') === UNIQUE_SESSION) {
            setPrice(formula_price)
            setPriceInCent(getPriceInCent(formula_price))
            setPaymentParameter(UNIQUE_SESSION)
            setIsCardForgotten(false)
          }
          else if (query.get('payment_parameter') === TRIAL) {
            setPrice(formula_price)
            setPriceInCent(getPriceInCent(formula_price))
            setPaymentParameter(TRIAL)
            setIsCardForgotten(false)
          }
          else if (query.get('payment_parameter') === TEN_SESSIONS) {
            setPrice(formula_price)
            setPriceInCent(getPriceInCent(formula_price))
            setPaymentParameter(TEN_SESSIONS)
            setIsCardForgotten(false)
          }
          else if (query.get('payment_parameter') === LONG_SUBSCRIPTION || query.get('payment_parameter') === ONE_MONTH_SUBSCRIPTION) {
            const contract_id = localStorage.getItem('contract_id')
            const contract_data = await fetchContractData(access_token, contract_id)
    
            setPrice(contract_data.first_month_debit)
            setPriceInCent(getPriceInCent(contract_data.first_month_debit))
            setPaymentParameter(query.get('payment_parameter') === LONG_SUBSCRIPTION ? LONG_SUBSCRIPTION : ONE_MONTH_SUBSCRIPTION)
            setIsCardForgotten(true)
            dispatch(setUserContractDataSigned(contract_data)) 
          }
        }
      }
      catch (err) {
        if (err.message) {
          setErrorMessage(err.message)
        }
        setToggleErrorModal(true)
      }
    })()
  }, [selected_formula, gym_data])


  return (
    <section>
      {redirection && <Redirect push to="/" />}
      {redirectHome && <Redirect push to="/" />}
      <Navbar />
      <Hero title={SECURE_PAYMENT} />
      {
       !price || !selected_formula
       ?
       <LoadSpinner />
       :
       <>
       </>
      }
      {price && selected_formula && <StripePaymentComponent
        text={AMOUNT_TO_PAY} 
        price={price} 
        price_in_cent={priceInCent} 
        gym_id={gym_id}
        forgot_card={isCardForgotten}
        setRedirectHome={setRedirectHome}
        selected_formula={selected_formula}
        payment_parameter={paymentParameter}
        unique_session={unique_session}
        trial_session={trial_session}
      />}
      <ErrorModal />
      <Footer />
    </section>
  )
}

export default PaymentComponent