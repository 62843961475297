import { Link } from "react-router-dom";
import { RiLock2Line } from "react-icons/ri";

import { UNLOCK_MY_ACCOUNT } from "../utils/text/button_titles";
import { UNLOCK_ACCOUNT } from "../utils/text/payment_parameters";

const Blocked = (props) => {
  return (
    <center>
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="box mx-6 my-6">
            <RiLock2Line color="#4c994a" size="8em" />
            <p className="title is-4 py-5">Oops ! votre compte est bloqué !</p>
            <p className="subtitle is-5">Vous n'avez pas honoré {props.user_session.max_session_missed} cours que vous avez réservé ce mois-ci, votre compte applique donc la pénalité conforme au contrat LA SALLE DU TEMPS.</p>
            <p className="subtitle is-5">Vous pourrez de nouveau prendre des rendez-vous le mois prochain ou bien débloquer votre compte dès maintenant en vous acquittant de {props.gym_data.unlock_account_price}€.</p>
          </div>
          <div className="has-text-centered my-6 py -6">
            <Link to={`/payment_component?payment_parameter=${UNLOCK_ACCOUNT}`}>
              <div className="button is-success">
                {UNLOCK_MY_ACCOUNT}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </center>
  )
}

export default Blocked;