import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setUniqueSession, setTrialSession } from "../features/appointments/appointmentSlice";
import { setNextAppointments, setPastAppointments } from "../features/appointments/appointmentSlice";

import UseUserIds from "../hooks/Use_user_ids";
import UseErrorModal from "../hooks/Use_error_modal";

import fetchAppointments from "../utils/fetch_appointments";
import { PAYMENT, CARD_FORGOTTEN, RETURN_HOME } from "../utils/text/button_titles";
import { url_api } from "../utils/config";
import recordNewUser from "../utils/record_new_user";
import recordUserLimitedFormula from "../utils/record_user_limited_formula";
import recordUniqueSession from "../utils/record_unique_session";
import { UNLOCK_ACCOUNT, UNIQUE_SESSION, LONG_SUBSCRIPTION, ONE_MONTH_SUBSCRIPTION, TEN_SESSIONS, TRIAL_SUBSCRIPTION, TRIAL } from "../utils/text/payment_parameters";
import unlockAccount from "../utils/unlock_account";
import recordTrialSession from "../utils/record_trial_session";
import { SUBSCRIPTION_RECORDED, UNIQUE_SESSION_RECORDED } from "../utils/text/various_messages";

const StripePaymentComponent = ({text, price, price_in_cent, gym_id, forgot_card, setRedirectHome, selected_formula, payment_parameter, unique_session, trial_session}) => {
  const dispatch = useDispatch()
  
  const [message, setMessage] = useState("");

  const [access_token, , user_id] = UseUserIds()
  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()

  useEffect(() => {
    (async () => {
      try {
        const contract_id = localStorage.getItem('contract_id')
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
          setMessage(SUBSCRIPTION_RECORDED);

          switch(payment_parameter) {
            case UNLOCK_ACCOUNT:
              console.log('UNLOCK ACCOUNT')
              const unlock_account = await unlockAccount(access_token, parseInt(user_id), gym_id)

              setMessage(unlock_account)
              break;
            case UNIQUE_SESSION:
              console.log('UNIQUE SESSION')
              setMessage(UNIQUE_SESSION_RECORDED)
              await recordUniqueSession(access_token, parseInt(user_id), gym_id, unique_session)
              localStorage.removeItem('unique_session')
              dispatch(setUniqueSession(null))
              break;
            // case TRIAL:
            //   console.log('UNIQUE SESSION')
            //   setMessage(UNIQUE_SESSION_RECORDED)
            //   await recordUniqueSession(access_token, parseInt(user_id), gym_id, unique_session)
            //   localStorage.removeItem('unique_session')
            //   dispatch(setUniqueSession(null))
            //   break;
            case LONG_SUBSCRIPTION:
              console.log('1 YEAR / 6 MONTHS / 3 MONTHS')
              await recordNewUser(access_token, parseInt(user_id), gym_id, contract_id)
              break;
            case ONE_MONTH_SUBSCRIPTION:
              console.log('ONE MONTH')
              await recordUserLimitedFormula(access_token, parseInt(user_id), gym_id, selected_formula)
              break;
            case TEN_SESSIONS:
              console.log('10 SESSIONS')
              await recordUserLimitedFormula(access_token, parseInt(user_id), gym_id, selected_formula)
              break;
            case TRIAL:
              console.log('TRIAL SUBSCRIPTION')
              setMessage(UNIQUE_SESSION_RECORDED)
              await recordTrialSession(access_token, parseInt(user_id), gym_id, trial_session)
              localStorage.removeItem('trial')
              dispatch(setTrialSession(null))
              const [next_appointments, past_appointments] = await fetchAppointments(access_token, user_id)

              dispatch(setNextAppointments(next_appointments))
              dispatch(setPastAppointments(past_appointments))
              break;
            default:
              console.log('HELLO WORLD')
          }
        }
        if (query.get("canceled")) {
          setMessage(false)
        }
      }
      catch (err) {
        console.log('err in the success ', err)
        setToggleErrorModal(true)
        if (err.message) {
          setErrorMessage(err.message)
        }
      }
    })()
  }, [payment_parameter]);

  const ProductDisplay = () => (
    <section className="columns my-6 py-6 mx-6 px-6">
      <div className="column is-6 is-offset-3 box">
        <div className="box has-text-centered">
          <img
            src="https://imageslsdt.s3.eu-west-3.amazonaws.com/LSDT_logo_definitif.png"
            alt="Logo de la franchise LSDT"
            width="112" height="28"
          />
        </div>  
        <div className="subtitle">
          <h3 className="has-text-weight-bold mb-2 pb-2">{text} :</h3>
          <h5>{price}€</h5>
        </div>
        <div className="my-2 py-2">
          <form action={`${url_api}/payments/create_checkout_session?gym_id=${gym_id}&prorata=${price_in_cent}&payment_parameter=${payment_parameter}&access_token=${access_token}`} method="POST">
            <button className="button is-success is-fullwidth is-normal" type="submit">
              {PAYMENT}
            </button>
          </form>
          {forgot_card && <button className="button is-info is-fullwidth is-normal mt-2" onClick={() => setRedirectHome(true)}>
            {CARD_FORGOTTEN}
          </button>}
        </div>
      </div>
    </section>
  );

  const Message = ({ message }) => (
    <section className="columns my-6 py-6 mx-6 px-6">
      <div className="column is-6 is-offset-3">
        <div className="box has-text-centered">
           <div className="subtitle is-size-3">{message}</div>
           <button className="button is-info is-fullwidth is-normal mt-2" onClick={() => setRedirectHome(true)}>
            {RETURN_HOME}
           </button>
        </div>
      </div>
    </section>
  );

  return message ? (
    <>
      <Message message={message} />
      <ErrorModal />
    </>
  ) : (
    <ProductDisplay />
  );
}

export default StripePaymentComponent;