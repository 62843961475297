import { url_api } from "./config"

const updateAppointment = async (token, user_id, gym_id, appointment_id, activity, hour, date) => {
  try {
    const update_appointment = await fetch(`${url_api}/appointments/users/app`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
        date: date,
        hour: hour,
        activity: activity ? activity
        : 'SUPER-TRAINING',
        appointment_id: appointment_id,
        gym_id: gym_id,
      },
        user_id: user_id
      }),
      mode: 'cors'
    })

    if (update_appointment.status !== 204) {
      update_appointment.json()
    }
  }
  catch (err) {
    throw err
  }
}

export default updateAppointment