import { url_api } from "./config";
import { WRONG_EMAIL } from "./text/error_messages";

const resetPassword = async (email) => {
  try {
    const reset_password = await fetch(`${url_api}/login/reset_password/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email: email
      }),
      mode: 'cors'
    })
    const reset_response = await reset_password.text()

    if (reset_response === WRONG_EMAIL) {
      throw new Error(WRONG_EMAIL)
    }
  }
  catch (err) {
    throw err
  }
}

export default resetPassword