import { url_api } from "./config";

const fetchPublicKey = async (token, user_id) => {
  try {
    const fetch_public_key = await fetch(`${url_api}/rsa_keys/${user_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    })
    const public_key = await fetch_public_key.json()

    return public_key.public_key
  } catch (err) {
    throw err
  }
}

export default fetchPublicKey