import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom'

import dayjs from 'dayjs'

import UseCountryCode from '../hooks/Use_country_code';

import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import { 
  CREATE_ACCOUNT,
  LAST_NAME,
  FIRST_NAME,
  GENDER, 
  MALE, 
  FEMALE, 
  ADRESS, 
  ZIP_CODE, 
  CITY, 
  MOBILE, 
  EMAIL, 
  BIRTH_DATE, 
  PASSWORD,
  CONFIRM_PASSWORD,
  PASSWORD_INFORMATIONS,
  INDICATIF
} from '../utils/text/various_messages'
import { ERROR } from '../utils/text/error_messages';
import { OK } from '../utils/text/button_titles';
import { VALIDATE } from '../utils/text/button_titles'
import ConfigureDatePickerBirthDate from '../items/Configure_date_picker_birth_date'
import fetchTrialAccessToken from '../utils/fetch_trial_access_token'
import ErrorModal from '../items/Error_modal'
import { setTrialToken } from '../features/token/tokenSlice'
import { setUserCreation } from '../features/user/userSlice'
import LoadSpinner from '../items/Load_spinner'
import checkPasswordFormat from '../utils/check_password_format';

function CreateAccount() {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [gender, setGender] = useState('');
  const [adress, setAdress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState(Date.parse(dayjs().subtract(15, 'years')));
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [toggleErrorModal, setToggleErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [redirection, setRedirection] = useState(false)
  const [isToken, setIsToken] = useState(false)
  const [countryCode, setCountryCode] = useState('+33')

  const formatedList = UseCountryCode()

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      try {
        const trial_access_token = await fetchTrialAccessToken()
        
        setIsToken(true)
        dispatch(setTrialToken(trial_access_token))
      }
      catch (err) {
        console.log(err)
      }
    })()
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    const check_response = checkPasswordFormat(password, confirmPassword)

    if (typeof check_response === 'string') {
      setErrorMessage(check_response)
      return setToggleErrorModal(true)
    }
    dispatch(setUserCreation({
      last_name: lastName,
      first_name: firstName,
      gender: gender,
      adress: adress,
      zip_code: zipCode,
      city: city,
      country_code: countryCode,
      phone_number: mobile,
      email: email,
      birth_date: birthDate,
      password: password
    }))
    return setRedirection(true)
  }

  return (
    <section>
      {
        redirection
        ?
        <Redirect push to="/select_gym" />
        :
        <>
          <Navbar />
          <Hero title={CREATE_ACCOUNT} />
          {!isToken && <LoadSpinner />}
          {isToken && <div className="columns">
            <div className="column is-6 is-offset-3">
              <form onSubmit={(e) => handleClick(e)} className="px-6 mx-6 box py-6 my-6">
                <div className="fields">
                  <label className="label has-text-black">{LAST_NAME}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="text" placeholder={LAST_NAME} name={LAST_NAME} value={lastName} onChange={(e) => setLastName(e.target.value.toLowerCase().trim())} required/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{FIRST_NAME}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="text" placeholder={FIRST_NAME} name={FIRST_NAME} value={firstName} onChange={(e) => setFirstName(e.target.value.toLowerCase().trim())} required/>
                    <span className="icon is-small is-left">
                      <i className="far fa-user"></i>
                    </span>
                  </div>
                </div>
                <br />
                <label className="label has-text-black">{GENDER}</label>
                <div className="control is-flex is-flex-direction-row is-justify-content-space-around">
                  <label className={GENDER}>
                    <input type="radio" name={GENDER} value="h" onClick={(e) => setGender(e.target.value)} required/>
                    {' '+MALE}
                  </label>
                  <label className={GENDER}>
                    <input type="radio" name={GENDER} value="f" onClick={(e) => setGender(e.target.value)} />
                    {' '+FEMALE}
                  </label>
                </div>
                <br />

                <div className="fields">
                  <label className="label has-text-black">{ADRESS}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="text" placeholder={ADRESS} name={ADRESS} value={adress} onChange={(e) => setAdress(e.target.value)} required/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-map-marker"></i>
                    </span>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{ZIP_CODE}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="number" placeholder={ZIP_CODE} name={ZIP_CODE} value={zipCode} onChange={(e) => setZipCode(e.target.value.trim())} required/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{CITY}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="text" placeholder={CITY} name={CITY} value={city} onChange={(e) => setCity(e.target.value.toLowerCase().trim())} required/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-city"></i>
                    </span>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{INDICATIF}</label>
                  <div className="control has-icons-left">
                    <div className="select">
                      <select required onChange={(e) => setCountryCode(e.target.value)}>
                        <option value="+33">+33</option>
                        {formatedList}
                      </select>
                    </div>
                    <span className="icon is-small is-left">
                      <i className="fas fa-plus"></i>
                    </span>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{MOBILE}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="tel" pattern="[0]{1}[6-7]{1}[0-9]{8}" placeholder={MOBILE} name={MOBILE} value={mobile} onChange={(e) => setMobile(e.target.value.trim())} required/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-mobile-alt"></i>
                    </span>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{EMAIL}</label>
                  <div className="control has-icons-left">
                    <input className="input" type="email" placeholder={EMAIL} name={EMAIL} value={email} onChange={(e) => setEmail(e.target.value.toLowerCase().trim())} required/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <br />
                <label className="label has-text-black">{BIRTH_DATE}</label>
                <ConfigureDatePickerBirthDate setBirthDate={setBirthDate} birth_date={birthDate} />
                <br />
                <div className="fields">
                  <label className="label has-text-black">{PASSWORD}</label>
                  <div className="control has-icons-left">
                    <input 
                      className="input" 
                      type="password" 
                      placeholder={PASSWORD} 
                      name={PASSWORD} 
                      value={password} 
                      onChange={(e) => setPassword(e.target.value.trim())} 
                      autoComplete="off"
                      required
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-lock"></i>
                    </span>
                    <p className="help is-info">{PASSWORD_INFORMATIONS}</p>
                  </div>
                </div>
                <br />
                <div className="fields">
                  <label className="label has-text-black">{CONFIRM_PASSWORD}</label>
                  <div className="control has-icons-left">
                    <input 
                      className="input" 
                      type="password" 
                      placeholder={CONFIRM_PASSWORD} 
                      name={CONFIRM_PASSWORD} 
                      value={confirmPassword} 
                      onChange={(e) => setConfirmPassword(e.target.value.trim())} 
                      autoComplete="off"
                      required
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-lock"></i>
                    </span>
                  </div>
                </div>
                <br />  
                <div className="control">
                  <button type="submit" className="button is-success is-fullwidth" value="Valider">{VALIDATE}</button>
                </div>
                <br />
              </form>
              <ErrorModal
                toggleErrorModal={toggleErrorModal} 
                setToggleErrorModal={setToggleErrorModal}
                ERROR={ERROR}
                OK={OK}
                ERROR_MESSAGE={errorMessage}
              />
            </div>
          </div>}
          <Footer />
        </>
      }
    </section>
  )
}

export default CreateAccount