import { Redirect } from 'react-router-dom'

import selectScreen from '../utils/select_screen';
import Navbar from '../items/Navbar'
import Footer from '../items/Footer'
import Hero from '../items/Hero'
import UseRedirection from '../hooks/Use_redirection'
import UseMultipleSelector from '../hooks/Use_multiple_selector';
import UseStartingData from '../hooks/Use_starting_data'
import selectScreenComponent from '../utils/select_screen_component';
import Message from '../items/Message';

function Booking() {
  UseStartingData()
  const [user_subscription, user_data, user_session, gym_data, gym_activities, access_token] = UseMultipleSelector()
  const redirection = UseRedirection()
  const selected_screen = selectScreen(user_data, user_subscription, user_session)
  const screen_component = selectScreenComponent(selected_screen, gym_data, gym_activities, user_data, access_token, user_session, user_subscription)

  return (
    <section>
      {redirection && <Redirect push to="/" />}
      <Navbar />
      <Hero title={`Enregistrer jusqu'à ${user_session ? user_session.max_session_authorized : '3'} séances à l'avance.`} />
      <Message />
      {
        screen_component
      }
      <Footer />
    </section>
  )
}

export default Booking