import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import './index.css';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import 'bulma/css/bulma.css'

import Booking from './components/Booking'
import Appointments from './components/Appointments'
import CreateAccount from './components/Create_account'
import SelectGym from './components/Select_gym';
import CompleteSubscription from './components/Complete_subscription'
import ContractPage from './components/Contract_page'
import ForgottenPassword from './components/Forgotten_password';
import Profile from './components/Profile';
import MyInformation from './components/My_informations';
import BookingUniqueSession from './components/Booking_unique_session_page'
import BookingTrialSession from './components/Booking_trial_session_page'
import PaymentComponent from './components/Payment_component';
import CompleteAuthorization from './components/Complete_authorization';
import AuthorizationPage from './components/Authorization_page'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/booking">
            <Booking />
          </Route>
          <Route exact path="/appointments">
            <Appointments />
          </Route>
          <Route exact path="/create_account">
            <CreateAccount />
          </Route>
          <Route exact path="/select_gym">
            <SelectGym />
          </Route>
          <Route exact path="/complete_subscription">
            <CompleteSubscription />
          </Route>
          <Route exact path="/contract_page">
            <ContractPage />
          </Route>
          <Route exact path="/payment_component">
            <PaymentComponent />
          </Route>
          <Route exact path="/forgotten_password">
            <ForgottenPassword />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/my_informations">
            <MyInformation />
          </Route>
          <Route exact path="/booking_unique_session">
            <BookingUniqueSession />
          </Route>
          <Route exact path="/booking_trial_session">
            <BookingTrialSession />
          </Route>
          <Route exact path="/complete_authorization">
            <CompleteAuthorization />
          </Route>
          <Route exact path="/authorization_page">
            <AuthorizationPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
