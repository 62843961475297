import { NEXT_APPOINTMENTS } from "../utils/text/link_title";
import selectModalTitle from "../utils/select_modal_title";
import selectAppointmentCellClassName from '../utils/select_appointment_cell_class_name'

const AppointmentCell = (appointment, index, APPOINTMENTS, setToggleModal, setDeletable, setModalTitle, setAppointmentId, setInitialAppointment) => {
  const presence_class_name = selectAppointmentCellClassName(appointment.presence)

  return (
    <article className={presence_class_name} key={index} onClick={() => {
      if (APPOINTMENTS === NEXT_APPOINTMENTS) {
        selectModalTitle(appointment, setModalTitle, setDeletable)
        setInitialAppointment(appointment)
        setAppointmentId(appointment.id)
        setToggleModal(true)
      }
    }}>
      <div className="message-body">
        <strong>{appointment.activity}</strong>
        <br />
        <strong>{appointment.date} - {appointment.hour}</strong>
      </div>
    </article>
  )
}

export default AppointmentCell