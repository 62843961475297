import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import tokenReducer from '../features/token/tokenSlice'
import userReducer from '../features/user/userSlice'
import gymReducer from '../features/gym/gymSlice'
import appointmentReducer from '../features/appointments/appointmentSlice'
import formulaReducer from '../features/formulas/formulaSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    token: tokenReducer,
    user: userReducer,
    gym: gymReducer,
    appointment: appointmentReducer,
    formula: formulaReducer
  },
});
