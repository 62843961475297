import { useDispatch, useSelector } from "react-redux"

import { setUniqueSession, selectUniqueSession } from '../features/appointments/appointmentSlice'

const UseUniqueSession = () => {
  const dispatch = useDispatch()

  let unique_session = useSelector(selectUniqueSession)

  if (unique_session) {
    return unique_session
  }
  else {
    unique_session = localStorage.getItem('unique_session')
    
    dispatch(setUniqueSession(JSON.parse(unique_session)))
    return JSON.parse(unique_session)
  }
}

export default UseUniqueSession