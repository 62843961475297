import getInvoiceSubscriptionData from './get_invoice_subscription_data'

const extractUserInformations = (user_data, user_subscription, user_session) => {
  const formula = user_subscription.Formula
  const invoice_subscription = user_subscription.InvoicesSubscriptions ? user_subscription.InvoicesSubscriptions[0] : null
  const [invoice_subscription_sessions_taken, invoice_subscription_sessions_number] = getInvoiceSubscriptionData(invoice_subscription)

  return [
    user_subscription.status,
    parseInt(user_session.sessions_taken),
    parseInt(user_session.max_session_authorized),
    user_session.blocked,
    formula.type,
    formula.is_unlimited_formula,
    formula.payment_type,
    invoice_subscription_sessions_taken,
    invoice_subscription_sessions_number,
    user_subscription.is_subscription_completed,
    user_subscription.is_session_opened
  ]
}

export default extractUserInformations




