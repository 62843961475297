import { useEffect, useState } from "react"

import { Redirect } from "react-router-dom"

import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'

import UseStartingData from '../hooks/Use_starting_data'
import UseMultipleSelector from "../hooks/Use_multiple_selector"
import UseErrorModal from "../hooks/Use_error_modal"
import UseUserIds from "../hooks/Use_user_ids"
import UseRedirection from '../hooks/Use_redirection'
import UseInfoModal from "../hooks/Use_info_modal"
import UseCountryCode from "../hooks/Use_country_code"

import ConfigureDatePickerBirthDate from '../items/Configure_date_picker_birth_date'
import Footer from "../items/Footer"
import Hero from "../items/Hero"
import Navbar from "../items/Navbar"
import LoadSpinner from '../items/Load_spinner'

import { 
  LAST_NAME, 
  FIRST_NAME, 
  GENDER, 
  MALE, 
  FEMALE, 
  ADRESS, 
  ZIP_CODE, 
  CITY, 
  MOBILE, 
  EMAIL,
  BIRTH_DATE, 
  PASSWORD, 
  PASSWORD_INFORMATIONS, 
  CONFIRM_PASSWORD,
  CHANGE_YOUR_INFORMATIONS,
  UPDATE_CONFIRMED,
  INDICATIF
} from "../utils/text/various_messages"
import { VALIDATE } from "../utils/text/button_titles"
import checkPasswordFormat from '../utils/check_password_format'
import updateUserData from '../utils/update_user_data'
import encryptPassword from "../utils/encrypt_password"

dayjs.extend(customParseFormat)

const MyInformation = () => {
  UseStartingData()
  
  const [, user_data, , , , ] = UseMultipleSelector()
  const [ErrorModal, setToggleErrorModal, setErrorMessage] = UseErrorModal()
  const [InfoModal, setToggleInfoModal, setInfoMessage, setRedirectionModal] = UseInfoModal()
  const [acccess_token, , user_id] = UseUserIds()
  const redirection = UseRedirection()

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [gender, setGender] = useState('');
  const [adress, setAdress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState(Date.parse(dayjs().subtract(15, 'years')));
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [redirect, setRedirect] = useState(false)
  const [countryCode, setCountryCode] = useState('+33')

  const formatedList = UseCountryCode()

  useEffect(() => {
    if (user_data && user_data.birth_date) {
      setBirthDate(Date.parse(dayjs(user_data.birth_date, 'DD-MM-YYYY')))
    }
  }, [user_data])

  const handleClick = async (e) => {
    try {
      e.preventDefault()
      if (password && confirmPassword) {
        const check_response = checkPasswordFormat(password, confirmPassword)

        if (typeof check_response === 'string') {
          throw new Error(check_response)
        }
      }
      const data_stored = {
        last_name: lastName,
        first_name: firstName,
        gender: gender,
        adress: adress,
        zip_code: zipCode,
        city: city,
        phone_number: mobile,
        email: email,
        birth_date: birthDate !== '' ? dayjs(birthDate).format('DD-MM-YYYY') : birthDate,
        password: password !== '' ? await encryptPassword(password) : password,
        country_code: countryCode
      }
      const data_to_update = {}

      for (let state in data_stored) {
        if (data_stored[state] !== '') {
          data_to_update[state] = data_stored[state]
        }
      }
      await updateUserData(acccess_token, user_id, data_to_update)

      setToggleInfoModal(true)
      setRedirectionModal(true)
      setInfoMessage(UPDATE_CONFIRMED)
      // setRedirect(true)
    }
    catch (err) {
      setToggleErrorModal(true)
      if (err.message) {
        setErrorMessage(err.message)
      }
    }
  }
  
  return (
    <section>
      {redirection && <Redirect push to="/" />}
      {redirect && <Redirect push to="/" />}
      <Navbar />
      <Hero title={CHANGE_YOUR_INFORMATIONS} />
      {!user_data && <LoadSpinner />}
      <div className="columns my-6 py-6">
        <div className="column is-6 is-offset-3">
        {user_data &&
          <form onSubmit={(e) => handleClick(e)} className="px-6 mx-6 box py-6 my-6">
          <div className="fields">
            <label className="label has-text-black">{LAST_NAME}</label>
            <div className="control has-icons-left">
              <input className="input" type="text" placeholder={user_data.last_name ? user_data.last_name : LAST_NAME} name={LAST_NAME} value={lastName} onChange={(e) => setLastName(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{FIRST_NAME}</label>
            <div className="control has-icons-left">
              <input className="input" type="text" placeholder={user_data.first_name ? user_data.first_name : FIRST_NAME} name={FIRST_NAME} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="far fa-user"></i>
              </span>
            </div>
          </div>
          <br />

          <label className="label has-text-black">{GENDER}</label>
          <div className="control is-flex is-flex-direction-row is-justify-content-space-around">
            <label className={GENDER}>
              <input type="radio" name={GENDER} value="h" onClick={(e) => setGender(e.target.value)} />
              {' '+MALE}
            </label>
            <label className={GENDER}>
              <input type="radio" name={GENDER} value="f" onClick={(e) => setGender(e.target.value)} />
              {' '+FEMALE}
            </label>
          </div>
          <br />

          <div className="fields">
            <label className="label has-text-black">{ADRESS}</label>
            <div className="control has-icons-left">
              <input className="input" type="text" placeholder={user_data.adress ? user_data.adress : ADRESS} name={ADRESS} value={adress} onChange={(e) => setAdress(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fas fa-map-marker"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{ZIP_CODE}</label>
            <div className="control has-icons-left">
              <input className="input" type="number" placeholder={user_data.zip_code ? user_data.zip_code : ZIP_CODE} name={ZIP_CODE} value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fas fa-map-marker-alt"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{CITY}</label>
            <div className="control has-icons-left">
              <input className="input" type="text" placeholder={user_data.city ? user_data.city : CITY} name={CITY} value={city} onChange={(e) => setCity(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fas fa-city"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{INDICATIF}</label>
            <div className="control has-icons-left">
              <div className="select">
                <select required onChange={(e) => setCountryCode(e.target.value)}>
                  {/* '+' + e.target.value.split('+')[1] */}
                  <option>{user_data.country_code ? user_data.country_code : INDICATIF}</option>
                  {formatedList}
                </select>
              </div>
              <span className="icon is-small is-left">
                <i className="fas fa-plus"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{MOBILE}</label>
            <div className="control has-icons-left">
              <input className="input" type="tel" pattern="[0]{1}[6-7]{1}[0-9]{8}" placeholder={user_data.phone_number ? user_data.phone_number : MOBILE} name={MOBILE} value={mobile} onChange={(e) => setMobile(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fas fa-mobile-alt"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{EMAIL}</label>
            <div className="control has-icons-left">
              <input className="input" type="email" placeholder={user_data.email ? user_data.email : EMAIL} name={EMAIL} value={email} onChange={(e) => setEmail(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <br />
          <label className="label has-text-black">{BIRTH_DATE}</label>
          <ConfigureDatePickerBirthDate setBirthDate={setBirthDate} birth_date={birthDate} />
          <br />
          <div className="fields">
            <label className="label has-text-black">{PASSWORD}</label>
            <div className="control has-icons-left">
              <input 
                className="input" 
                type="password" 
                placeholder={PASSWORD} 
                name={PASSWORD} 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                autoComplete="off"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <p className="help is-info">{PASSWORD_INFORMATIONS}</p>
            </div>
          </div>
          <br />
          <div className="fields">
            <label className="label has-text-black">{CONFIRM_PASSWORD}</label>
            <div className="control has-icons-left">
              <input 
                className="input" 
                type="password" 
                placeholder={CONFIRM_PASSWORD} 
                name={CONFIRM_PASSWORD} 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                autoComplete="off"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
            </div>
          </div>
          <br />
          <div className="control">
            <button type="submit" className="button is-success is-fullwidth" value="Valider">{VALIDATE}</button>
          </div>
          <br />
        </form>
        }
        </div>
      </div>
      <Footer />
      <InfoModal />
      <ErrorModal />
    </section>
  )
}

export default MyInformation