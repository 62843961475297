

const generateImageRightText = (gym_data) => {
  return (
    `J’autorise, à titre gratuit ${
      gym_data.legal_name
    } et SARL SALLE DU TEMPS:\n- A me filmer et me photographier lors de mes temps de présence à LA SALLE DU TEMPS et à toutes occasions événements ou compétitions organisés par ${
      gym_data.legal_name
    } la SARL SALLE DU TEMPS.\n- A effectuer un montage, reproduire et diffuser ces images sur tous les supports de communications jugés nécessaires.\n- A publier ces images sur le web notamment via les réseaux sociaux. Je peux me rétracter à tout moment, sur simple demande écrite par voie postale : LA SALLE DU TEMPS ${
      gym_data.name
    }, ${gym_data.adress}, ${gym_data.city}, ${
      gym_data.zip_code
    } et par voie électronique à: ${
      gym_data.administrator_email
    }\n\nJe m’engage à ne pas tenir responsable la personne ou structure précitée ainsi que ses représentantes et toute personne agissant avec sa permission pour ce qui relève de la possibilité d’un changement de cadrage, de couleur et de densité qui pourrait survenir lors de la reproduction. Ce contrat est reconduit de manière tacite chaque année. La présente autorisation est personnelle et incessible et ne s'applique qu'aux supports explicitement mentionnés.`
  )
}

export default generateImageRightText