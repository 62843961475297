import { UPDATE_APPOINTMENT, UPDATE_OR_DELETE_APPOINTMENT, DELETE_APPOINTMENT, UPDATE_APPOINTMENT_TODAY } from "./text/modal_titles"

const selectModalTitle = (appointment, setModalTitle, setDeletable) => {
  if (
    appointment.is_deletable &&
    !appointment.is_editable &&
    appointment.is_editable_only_today
  ) {
    setDeletable(false)
    return setModalTitle(UPDATE_APPOINTMENT_TODAY)
  } else if (
    appointment.is_deletable &&
    appointment.is_editable &&
    !appointment.is_editable_only_today
  ) {
    setDeletable(true)
    return setModalTitle(UPDATE_OR_DELETE_APPOINTMENT)
  } else if (
    appointment.is_deletable &&
    !appointment.is_editable &&
    !appointment.is_editable_only_today
  ) {
    setDeletable(true)
    return setModalTitle(DELETE_APPOINTMENT)
  } else if (
    !appointment.is_deletable &&
    appointment.is_editable &&
    !appointment.is_editable_only_today
  ) {
    setDeletable(false)
    return setModalTitle(UPDATE_APPOINTMENT)
  } else if (
    !appointment.is_deletable &&
    !appointment.is_editable &&
    appointment.is_editable_only_today
  ) {
    setDeletable(false)
    return setModalTitle(UPDATE_APPOINTMENT_TODAY)
  }
}

export default selectModalTitle