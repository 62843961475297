import { url_api } from "./config";

const updateUserData = async (acccess_token, user_id, user_data) => {
  try {

    const update_user_data = await fetch(`${url_api}/users/${user_id}/app`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${acccess_token}`,
      },
      body: JSON.stringify({
        data: user_data
      }),
      mode: 'cors',
    })
    const updated_user_data = await update_user_data.json();

    return updated_user_data
  }
  catch (err) {
    throw err
  }
}

export default updateUserData