import { RiPauseCircleLine } from 'react-icons/ri'

const Breaked = () => {
  return (
    <center>
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="box mx-6 my-6">
            <RiPauseCircleLine color="#4c994a" size="8em" />
            <p className="title is-4 py-5">Votre compte est en pause</p>
            <p className="subtitle is-5">Conformément à votre demande, nous avons mis votre abonnement en pause. Vous pourrez reprendre vos cours après avoir contacter le responsable de LA SALLE DU TEMPS.</p>
          </div>
        </div>
      </div>
    </center>
  )
}

export default Breaked;