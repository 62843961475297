import { useEffect, useState } from "react"

import { ERROR, ERROR_DEFAULT_MESSAGE } from "../utils/text/error_messages"
import { OK } from "../utils/text/button_titles"

const UseErrorModal = () => {  
  const [toggleErrorModal, setToggleErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(ERROR_DEFAULT_MESSAGE)

  useEffect(() => {
    if (toggleErrorModal) {
      setToggleErrorModal(true)
      setErrorMessage(errorMessage)
    }
  }, [])

  const ErrorModal = () => {
    return (
      <>
        <div className={toggleErrorModal ? 'modal is-active' : 'modal'}>
          <div className="modal-background" />
          <div className="modal-card px-6">
            <header className="modal-card-head has-background-danger has-text-white">
              <p className="modal-card-title has-text-white">{ERROR}</p>
              <button className="delete" aria-label="close" onClick={() => setToggleErrorModal(false)} />
            </header>
            <section className="modal-card-body">
              <h4 className="sutitle">{errorMessage}</h4>
              <br />
              <div className="has-text-centered">
                <button className="button is-fullwidth" onClick={() => setToggleErrorModal(false)}>{OK}</button>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }

  return [ErrorModal, setToggleErrorModal, setErrorMessage]
}

export default UseErrorModal