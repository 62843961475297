import { RevolvingDot } from  'react-loader-spinner'

import { WAITING_TEXT } from '../utils/text/various_messages'

const LoadSpinner = () => {
  return (
    <div className='columns py-6 my-6 mx-3 px-3'>
      <div className='column is-6 is-offset-3'>
        <div className='box has-text-centered'>
          <div className='is-flex is-flex-direction-row is-justify-content-center'>
            <RevolvingDot
              height="100"
              width="100"
              color='#3dc487'
              ariaLabel='loading'
            />
          </div>
          <div className='py-2 has-text-weight-bold'>{WAITING_TEXT}</div>
        </div>
      </div>
    </div>
  )
}

export default LoadSpinner