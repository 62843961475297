const getPriceInCent = (price) => {
  let price_in_cent = price.split('.')

  if (!price.includes('.')) {
    price_in_cent = price+'00'
  }
  else if (price.includes('.')) {
    price_in_cent = price_in_cent[1].length === 2 ? price_in_cent.join('') : price_in_cent.join('')+'0'
  }
  return price_in_cent
}

export default getPriceInCent