import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  next_appointment: null,
  past_appointment: null,
  unique_session: null,
  trial_session: null
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setNextAppointments: (state, action) => {
      state.next_appointment = action.payload;
    },
    setPastAppointments: (state, action) => {
      state.past_appointment = action.payload
    },
    setUniqueSession: (state, action) => {
      state.unique_session = action.payload
    },
    setTrialSession: (state, action) => {
      state.trial_session = action.payload
    },
    resetAppointments: (state) => {
      state.next_appointment = null;
      state.past_appointment = null;
      state.unique_session = null;
      state.trial_session = null;
    }
  }
});

export const selectNextAppointments = (state) => state.appointment.next_appointment;
export const selectPastAppointments = (state) => state.appointment.past_appointment;
export const selectUniqueSession = (state) => state.unique_session;
export const selectTrialSession = (state) => state.trial_session;

export const { setNextAppointments, setPastAppointments, setUniqueSession, setTrialSession, resetAppointments } = appointmentSlice.actions;

export default appointmentSlice.reducer;
