const createContactJsx = (name, adress, zip_code, city, administrator_email, phone_number) =>  {
  const contact_jsx = <p className="subtitle">
                                  {name},
                                  <br />
                                  {adress},
                                  <br />
                                  {zip_code}, {city}
                                  <br />
                                  {administrator_email}
                                  <br />
                                  {phone_number}
                                </p>
  return contact_jsx
}

export default createContactJsx